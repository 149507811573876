import clsx from "clsx";
import linkifyHtml from "linkify-html";
import type { ReactNode } from "react";
import SanitizedHTML from "shared_utils/src/SanitizedHTML";
import type { AnswerLibraryEntry } from "../../features/KnowledgeLibrary/types";
import { useFeatureFlag } from "../../utils/hooks";
import styles from "./AnswerDetails.module.scss";

const classForAnswer = (answer: string) => {
  switch (answer.toLocaleLowerCase()) {
    case "yes":
      return styles.yes;
    case "no":
      return styles.no;
    case "n/a":
      return styles.not_applicable;
    default:
      return undefined;
  }
};

interface AnswerBubbleProps {
  answer: string;
  className?: string;
  newDesign?: boolean;
}

export const AnswerBubble = ({
  answer,
  className,
  newDesign = false,
}: AnswerBubbleProps): JSX.Element => {
  if (newDesign) {
    return (
      <div
        className={clsx(
          styles.AnswerBubble,
          styles.AnswerBubble_New,
          classForAnswer(answer),
          className,
        )}
      >
        {answer.toLocaleUpperCase()}
      </div>
    );
  }

  return (
    <span
      className={clsx(styles.AnswerBubble, classForAnswer(answer), className)}
    >
      {answer.toLocaleUpperCase()}
    </span>
  );
};

interface AnswerDetailsProps {
  entry: AnswerLibraryEntry;
  textTransform?: (text: string) => ReactNode;
  htmlTransform?: (text: string) => string;
  knowledgeLibraryImagesEnabled?: boolean;
}

const AnswerDetails = ({
  entry,
  textTransform,
  htmlTransform,
  knowledgeLibraryImagesEnabled = false,
}: AnswerDetailsProps): JSX.Element => {
  const { answer, details, detailsHtml } = entry.attributes;
  // Note: We need to linkify details before applying the textTransform for
  // links to be preserved with highlighting
  const linkifiedDetailsHtml = detailsHtml
    ? linkifyHtml(detailsHtml)
    : detailsHtml;
  const newKLDesignEnabled = useFeatureFlag("new-kl-design-enabled");
  return (
    <div className={clsx(newKLDesignEnabled ? styles.NewDesignContainer : "")}>
      {!!answer && (
        <>
          <AnswerBubble
            answer={answer}
            className={styles.OffsetAnswer}
            newDesign={newKLDesignEnabled}
          />
          {/* Without this space, double-clicking on AnswerBubble will also
          highlight first word of `details` */}
          &nbsp;
        </>
      )}
      {/* NOTE: Be careful when removing this feature flag.  Old/existing entries
      won't have detailsHtml, so we will always need to check for both. */}
      {knowledgeLibraryImagesEnabled && linkifiedDetailsHtml ? (
        <SanitizedHTML
          html={
            htmlTransform
              ? htmlTransform(linkifiedDetailsHtml)
              : linkifiedDetailsHtml
          }
        />
      ) : (
        details && (textTransform ? textTransform(details) : details)
      )}
    </div>
  );
};

export default AnswerDetails;
