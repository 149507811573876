import { Column } from "@ant-design/plots";
import { Skeleton } from "antd";
import { useMemo } from "react";
import { useGetConciergePendingQuestionAnalyticsQuery } from "../../../features/API/AnalyticsDashboard";
import { filterData, getAnalyticsMessageComponent } from "../utils";
import { ConceirgeChartProps } from "./ConciergeContainer";

/**
 * Component that displays metrics for pending questions using a column chart
 * @param {PendingQuestionsColumnChartProps} props - Component props
 */
const PendingQuestionsColumnChart = ({
  productId,
  activeFilter,
  theme,
  height,
  width,
}: ConceirgeChartProps): JSX.Element => {
  const {
    data: pendingQuestionStats,
    isError: statsError,
    isLoading: statsLoading,
  } = useGetConciergePendingQuestionAnalyticsQuery({
    productId,
  });

  const filteredData = useMemo(() => {
    if (!pendingQuestionStats?.data?.pendingQuestionsStats) return [];

    return filterData(
      pendingQuestionStats?.data?.pendingQuestionsStats,
      activeFilter,
      true,
    );
  }, [pendingQuestionStats, activeFilter]);

  if (statsLoading) {
    return <Skeleton active />;
  }

  if (statsError) {
    return getAnalyticsMessageComponent({
      type: "error",
      title: "Failed to Load Pending Questions Statistics",
      subTitle:
        "An unexpected error occurred while fetching Pending Questions data",
    });
  }

  if (
    !pendingQuestionStats ||
    !pendingQuestionStats?.data?.pendingQuestionsStats ||
    pendingQuestionStats?.data?.pendingQuestionsStats.every(
      (stat) => stat.value === 0,
    )
  ) {
    return getAnalyticsMessageComponent({
      type: "info",
      title: "No Pending Questions Data",
      subTitle: "There are no Pending Questions statistics available",
    });
  }

  const chartConfig = {
    theme,
    data: filteredData,
    xField: "date",
    yField: "value",
    colorField: "type",
    stack: false,
    height,
    width,
    sort: {
      reverse: false,
      by: "x",
    },
    // Add some basic styling and responsiveness
    padding: "auto",
    xAxis: {
      label: {
        autoRotate: true,
      },
    },
    axis: {
      y: { title: "Questions" },
      x: { title: "Month" },
    },
    style: {
      maxWidth: 20,
    },
  };

  return <Column {...chartConfig} />;
};

export default PendingQuestionsColumnChart;
