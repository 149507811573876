import { PrivateDataRoomLinkId } from "shared_frontend/src/types/assuranceProfile";
import { ReportId } from "../features/API/AltitudeReports/types";
import {
  AccessGroupId,
  DashboardQuestionnaireStatus,
  UserId,
} from "../features/API/types";
import { QuestionnaireTaskId } from "../features/MissionControl/types/tasks";

export const BASE_API = window.location.origin;

const MICROSOFT_LOGIN_REDIRECT_URI = `${BASE_API}/login`;

const microsoftClientId =
  window.ENV_VARIABLE_MICROSOFT_CLIENT_ID ||
  import.meta.env.VITE_MICROSOFT_CLIENT_ID;
export const MICROSOFT_LOCATION_REPLACE_LOGIN_URI = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${microsoftClientId}&response_type=code&redirect_uri=${MICROSOFT_LOGIN_REDIRECT_URI}&scope=offline_access%20user.read`;

export const DEFAULT_PAGE_SIZE = 20;
export const DEBOUNCE_TIME_MILLISECONDS = 500;

export const ConstantParameters = {
  dashboard: {
    PAGE_QUERY_KEY: "page",
    COMPANY_ID_QUERY_KEY: "companyId",
  },
  profilesTable: {
    COMPANY_ID: "companyId",
    CREATE_LINK_URL_HASH: "create-data-room-link",
  },
  homepage: {
    COMPANY_ID: "companyId",
  },
  assuranceProfile: {
    COMPANY_ID: "companyId",
  },
  vendorAssess: {
    COMPANY_ID_QUERY_KEY: "companyId",
    PAGE_QUERY_KEY: "page",
  },
  altitudeReports: {
    COMPANY_ID: "companyId",
  },
};

export const APIEndpoints = {
  analyticsDashboard: {
    GET_CONCIERGE_ANNUAL_REVENUE_ANALYTICS:
      "/api/analytics-dashboard/get-concierge-annual-revenue-analytics",
    GET_CONCIERGE_COMPLETION_PERCENTAGE_ANALYTICS:
      "/api/analytics-dashboard/get-concierge-completion-percentage-analytics",
    GET_CONCIERGE_PENDING_QUESTION_ANALYTICS:
      "/api/analytics-dashboard/get-concierge-pending-question-analytics",
    GET_CONCIERGE_TIME_SAVED_ANALYTICS:
      "/api/analytics-dashboard/get-concierge-time-saved-analytics",
    GET_CONCIERGE_KL_UPDATE_ANALYTICS:
      "/api/analytics-dashboard/get-concierge-kl-update-analytics",
    GET_ASSURANCE_PROFILE_PAGE_VISITS_ANALYTICS:
      "/api/analytics-dashboard/get-assurance-profile-page-visits-analytics",
    GET_CONCIERGE_COPILOT_SEARCH_ANALYTICS:
      "/api/analytics-dashboard/get-concierge-copilot-search-analytics",
    GET_CONCIERGE_KL_SEARCH_ANALYTICS:
      "/api/analytics-dashboard/get-concierge-kl-search-analytics",
    GET_ASSURANCE_PROFILE_PRIVATE_DATA_ROOM_ANALYTICS:
      "/api/analytics-dashboard/get-assurance-profile-private-data-room-analytics",
    GET_ASSURANCE_PROFILE_INFORMATION_REQUESTS_ANALYTICS:
      "/api/analytics-dashboard/get-assurance-profile-information-requests-analytics",
    GET_VENDOR_ASSESS_TIME_SAVED_ANALYTICS:
      "/api/analytics-dashboard/get-vendor-assess-time-saved-analytics",
    GET_VENDOR_ASSESS_COMPLETED_BY_MONTH_ANALYTICS:
      "/api/analytics-dashboard/get-vendor-assess-completed-by-month-analytics",
    GET_ASSURANCE_PROFILE_DOCUMENTS_DOWNLOAD_ANALYTICS:
      "/api/analytics-dashboard/get-assurance-profile-documents-download-analytics",
  },
  socialAuth: {
    GOOGLE_LOGIN: "/api/social_auth/google/login",
    MICROSOFT_LOGIN: "/api/social_auth/microsoft/login",
  },
  admin: {
    DEACTIVATE_COMPANY: "/api/admin/deactivate-company",
    REDACT_COMPANY_DATA: "/api/admin/redact-company-data",
    CREATE_COMPANY: "/api/admin/create-company",
    EDIT_COMPANY: "/api/admin/edit-company",
    CREATE_PRODUCT: "/api/admin/create-product",
    EDIT_PRODUCT: "/api/admin/edit-product",
    ARCHIVE_PRODUCT: "/api/admin/archive-product",
  },
  announcements: {
    GET_ANNOUNCEMENTS: "/api/announcements/get-announcements",
  },
  auth: {
    LOGIN: "/api/auth/login",
    REFRESH_TOKEN: "/auth/token/refresh",
    REGISTER: "/api/auth/register",
    LOGOUT: "/api/auth/logout",
    SEND_RESET_CODE: "/api/auth/request-reset-password",
    RESET_PASSWORD: "/api/auth/reset-password",
    UPDATE_PASSWORD: "/api/auth/update-password",
    ENROLL_MFA: "/api/auth/enroll-mfa",
    UNENROLL_MFA: "/api/auth/unenroll-mfa",
    VALIDATE_MFA: "/api/auth/validate-mfa",
    ENFORCE_MFA: "/api/auth/enforce-mfa",
    GET_COMPANY_SETTINGS: "/api/auth/get-company-settings",
    AUTO_ACTIVATE_NEW_USERS: "/api/auth/auto-activate-new-users",
    GET_ME: "/api/auth/me",
    CHECK_AUTH_PROVIDER: "/api/auth/check-auth-provider",
    SSO_CALLBACK: "/api/auth/sso-callback",
    CLEAR_IMPERSONATED_USER: "/api/auth/clear-impersonated-user",
    IMPERSONATE_USER: "/api/auth/impersonate-user",
    CURRENTLY_IMPERSONATING: "/api/auth/currently-impersonating",
  },
  answerLibrary: {
    ADD_ENTRY: "/api/answer-library/add-entry",
    ADD_TAGS: "/api/answer-library/add-tags-to-entities",
    BULK_UPLOAD: "/api/answer-library/create-bulk-upload-qa-pairs-request",
    BULK_ADD_REVIEWS: "/api/answer-library/bulk-add-reviews",
    BULK_UPDATE_REVIEW_CYCLE: "/api/answer-library/bulk-update-review-cycle",
    BULK_UPDATE_OWNERS: "/api/answer-library/bulk-update-owners",
    DELETE_TAG: "/api/answer-library/delete-tag",
    FIND_SEARCHABLE_QUERY: "/api/answer-library/find-searchable-query",
    GET_CATEGORIES: "/api/answer-library/get-categories",
    GET_CANONICAL_QUESTION: "/api/answer-library/get-canonical-question",
    GET_ENTRIES: "/api/answer-library/get-entries",
    GET_PRODUCTS: "/api/answer-library/get-products",
    MERGE_QUESTIONS: "/api/answer-library/merge-questions",
    REMOVE_CANONICAL_QUESTIONS:
      "/api/answer-library/remove-canonical-questions",
    UPDATE_CANONICAL_QUESTION: "/api/answer-library/update-question",
    UPDATE_PRODUCT: "/api/answer-library/update-product",
    UPDATE_TAG: "/api/answer-library/update-tag",
    GET_COMPANIES: "/api/answer-library/get-companies",
    GET_TAGS: "/api/answer-library/get-tags",
    CREATE_EXPORT_CSV_REQUEST: "/api/answer-library/create-export-csv-request",
    EXPORT_CSV: "/api/answer-library/export-csv",
    CREATE_TAG: "/api/answer-library/create-tag",
    BULK_UPLOAD_TAGS: "/api/answer-library/bulk-upload-tags",
    REMOVE_TAGS: "/api/answer-library/remove-tags-from-entities",
    UPLOAD_IMAGES: "/api/answer-library/upload-images",
    UPDATE_PRISM_ENTRY: "/api/answer-library/update-prism-entry",
    POST_PROCESS_BULK_IMAGE_UPLOAD_REQUEST:
      "/api/answer-library/post-process-bulk-image-upload-request",
    demoCompanies: {
      CREATE_DEMO_COMPANY:
        "/api/answer-library/demo-companies/create-demo-company",
    },
  },
  answerRequests: {
    GET_ANSWER_REQUESTS: "/api/answer-requests/get-answer-requests",
    GET_ANSWER_REQUEST_BY_URL: "/api/answer-requests/get-answer-request-by-url",
    ADD_ANSWER_REQUEST: "/api/answer-requests/add-answer-request",
    CLOSE_ANSWER_REQUEST: "/api/answer-requests/close-answer-request",
    COMPLETE_ANSWER_REQUEST: "/api/answer-requests/complete-answer-request",
    PUBLIC_COMPLETE_ANSWER_REQUEST:
      "/api/answer-requests/public-complete-answer-request",
  },
  concierge: {
    DOWNLOAD_ATTACHMENT: "/api/concierge/download-attachment",
    ACCEPT_TASK_EXISTING_THREAD:
      "/api/concierge/accept-questionnaire-task-on-thread",
    CREATE_TASK: "/api/concierge/create-questionnaire-task",
    CANCEL_TASK: "/api/concierge/cancel-questionnaire-task",
    ACCEPT_TASK: "/api/concierge/accept-questionnaire-task",
    RETURN_COMPLETED_TASK: "/api/concierge/return-completed-questionnaire",
    POST_PROCESS_RETURN_COMPLETED_TASK:
      "/api/concierge/post-process-return-completed-questionnaire",
    UPDATE_QUESTIONNAIRE_TASK_META:
      "/api/concierge/update-questionnaire-task-meta",
    UPDATE_QUESTIONNAIRE_TASK_ASSIGNED_CUSTOMER:
      "/api/concierge/update-questionnaire-task-assigned-customer",
    UPDATE_TASK_PRODUCTS: "/api/concierge/update-questionnaire-task-products",
    UPLOAD_FINAL_VERSION: (questionnaireTaskId: QuestionnaireTaskId): string =>
      `/api/concierge/questionnaire-task/${questionnaireTaskId}/finalize`,
    GET_QUESTIONNAIRE_DUE_DATE: "/api/concierge/get-questionnaire-due-date",
    GET_COLLABORATOR_EMAILS: "/api/concierge/collaborator-emails",
    GET_ROE: "/api/concierge/get-roe",
    UPDATE_TASK_TAGS: "/api/concierge/update-questionnaire-task-tags",
    CREATE_API_TOKEN: "/api/concierge/create-api-token",
    DELETE_API_TOKEN: "/api/concierge/delete-api-token",
    GET_API_TOKENS: "/api/concierge/get-api-tokens",
    REVOKE_API_TOKEN: "/api/concierge/revoke-api-token",
    EDIT_API_TOKEN: "/api/concierge/edit-api-token",
    UPDATE_QUESTIONNAIRE_TASK_ANNUAL_RECURRING_REVENUE:
      "/api/concierge/update-questionnaire-task-annual-recurring-revenue",
    UPDATE_QUESTIONNAIRE_TASK_PROSPECT_DUE_DATE:
      "/api/concierge/update-questionnaire-task-prospect-due-date",
    GET_QUESTIONNAIRES_WITH_PRISM_ENTRIES:
      "/api/concierge/get-questionnaires-with-prism-entries",
    GET_QUESTIONNAIRE_PRISM_ENTRIES:
      "/api/concierge/get-questionnaire-prism-entries",
    MARK_PRISM_COMPLETION: "/api/concierge/mark-prism-completion",
  },
  customerAssurance: {
    publicKeys: {
      ADD_PUBLIC_KEY: "/api/customer-assurance/public-keys/add-public-key",
      GET_ISSUER_ID: "/api/customer-assurance/public-keys/get-issuer-id",
      VALIDATE_JWT: (id: PrivateDataRoomLinkId) =>
        `/api/customer-assurance/public-keys/${id}/validate-jwt`,
    },
    profiles: {
      CREATE_PROFILE: "/api/customer-assurance/profiles/create-profile",
      GET_PROFILE: "/api/customer-assurance/profiles/get-profile",
      GET_PROFILES: "/api/customer-assurance/profiles/get-profiles",
      UPDATE_PROFILE: "/api/customer-assurance/profiles/update-profile",
      DELETE_PROFILE: "/api/customer-assurance/profiles/delete-profile",
      GET_PRIVATE_DATA_ROOM_LINKS:
        "/api/customer-assurance/profiles/get-private-data-room-links",
      GET_USAGE_STATS: "/api/customer-assurance/profiles/get-usage-stats",
      EXPORT_USAGE_STATS: "/api/customer-assurance/profiles/export-usage-stats",
      GET_SUBSCRIBERS: "/api/customer-assurance/profiles/get-subscribers",
      GET_SALESFORCE_AUTH_URL:
        "/api/customer-assurance/profiles/get-salesforce-auth-url",
      CHECK_EXISTING_SALESFORCE_TOKENS:
        "/api/customer-assurance/profiles/check-existing-salesforce-tokens",
      UPDATE_COMPANY_SALESFORCE_INTEGRATION:
        "/api/customer-assurance/profiles/update-company-salesforce-integration",
      DISCONNECT_SALESFORCE:
        "/api/customer-assurance/profiles/disconnect-salesforce",
      GET_NDA_IN_PLACE_EMAILS:
        "/api/customer-assurance/profiles/get-nda-in-place-emails",
      CHECK_NDA_IN_PLACE_FOR_EMAIL_USER:
        "/api/customer-assurance/profiles/check-nda-in-place-for-email-user",
      PROFILE_SWITCHERS: "/api/customer-assurance/profiles/profile-switcher",
      UPDATE_PROFILE_SWITCHER:
        "/api/customer-assurance/profiles/update-profile-switcher",
      GET_PROFILE_SWITCHERS:
        "/api/customer-assurance/profiles/get-profile-switchers",
      DELETE_PROFILE_SWITCHER:
        "/api/customer-assurance/profiles/delete-profile-switcher",
    },
    profileVersions: {
      GET_PROFILE_VERSION:
        "/api/customer-assurance/profile-versions/get-profile-version",
      GET_PUBLIC_PROFILE_VERSION:
        "/api/customer-assurance/profile-versions/get-public-profile-version",
      GET_PROFILE_VERSIONS:
        "/api/customer-assurance/profile-versions/get-profile-versions",
      CREATE_DRAFT_PROFILE_VERSION:
        "/api/customer-assurance/profile-versions/create-draft-profile-version",
      DELETE_DRAFT_PROFILE_VERSION:
        "/api/customer-assurance/profile-versions/delete-draft-profile-version",
      UPDATE_OVERVIEW:
        "/api/customer-assurance/profile-versions/update-profile-version-overview",
      UPDATE_OVERVIEW_TITLE:
        "/api/customer-assurance/profile-versions/update-profile-version-overview-title",
      UPDATE_TITLE:
        "/api/customer-assurance/profile-versions/update-profile-version-title",
      UPDATE_IN_USE:
        "/api/customer-assurance/profile-versions/update-profile-version-in-use",
      FINALIZE_DRAFT_VERSION:
        "/api/customer-assurance/profile-versions/finalize-draft-version",
      CHANGE_PROFILE_VERSION_TYPE:
        "/api/customer-assurance/profile-versions/change-profile-version-type",
      UPDATE_HTML_HEAD:
        "/api/customer-assurance/profile-versions/update-profile-version-html-head",
      UPDATE_COMPONENT_ORDER:
        "/api/customer-assurance/profile-versions/update-component-order",
      UPDATE_PROFILE_SWITCHER:
        "/api/customer-assurance/profile-versions/update-switcher-for-profile-version",
    },
    certifications: {
      UPDATE_CERTIFICATION_SELECTIONS:
        "/api/customer-assurance/certifications/update-certification-selections",
      GET_CERTIFICATIONS:
        "/api/customer-assurance/certifications/get-certifications",
      CREATE_CERTIFICATION:
        "/api/customer-assurance/certifications/create-certification",
      POST_PROCESS_AFTER_CERTIFICATION_UPLOAD:
        "/api/customer-assurance/certifications/post-process-after-certification-upload",
      UPDATE_CERTIFICATION:
        "/api/customer-assurance/certifications/update-certification",
      DELETE_CERTIFICATION:
        "/api/customer-assurance/certifications/delete-certification",
      UPDATE_CERTIFICATION_SELECTION_RANK:
        "/api/customer-assurance/certifications/update-certification-selection-rank",
    },
    documents: {
      DOWNLOAD_PUBLIC_DOCUMENT:
        "/api/customer-assurance/documents/download-public-document",
      DOWNLOAD_PRIVATE_DOCUMENT:
        "/api/customer-assurance/documents/download-private-document",
      DOWNLOAD_WATERMARKED_DOCUMENT:
        "/api/customer-assurance/documents/download-watermarked-document",
      BULK_DOWNLOAD_WATERMARKED_DOCUMENTS:
        "/api/customer-assurance/documents/bulk-download-watermarked-documents",
      GET_DOCUMENTS: "/api/customer-assurance/documents/get-documents",
      GET_PRIVATE_DOCUMENTS:
        "/api/customer-assurance/documents/get-private-documents",
      CREATE_DOCUMENT: "/api/customer-assurance/documents/create-document",
      DELETE_DOCUMENT: "/api/customer-assurance/documents/delete-document",
      UPDATE_DOCUMENT: "/api/customer-assurance/documents/update-document",
      USER_AUTH_BULK_DOWNLOAD_CUSTOMER_ASSURANCE_DOCUMENTS:
        "/api/customer-assurance/documents/user-auth-bulk-download-public-documents",
      UPDATE_DOCUMENT_ORDER:
        "/api/customer-assurance/documents/update-document-order",
    },
    documentGroups: {
      CREATE_DOCUMENT_GROUP:
        "/api/customer-assurance/document-groups/create-document-group",
      UPDATE_DOCUMENT_GROUP:
        "/api/customer-assurance/document-groups/update-document-group",
      DELETE_DOCUMENT_GROUP:
        "/api/customer-assurance/document-groups/delete-document-group",
      GET_DOCUMENT_GROUPS:
        "/api/customer-assurance/document-groups/get-document-groups",
    },
    tiles: {
      CREATE_TILE: "/api/customer-assurance/tiles/create-tile",
      UPDATE_TILE: "/api/customer-assurance/tiles/update-tile",
      UPDATE_TILE_RANK: "/api/customer-assurance/tiles/update-tile-rank",
      DELETE_TILE: "/api/customer-assurance/tiles/delete-tile",
      CREATE_TILE_ITEM: "/api/customer-assurance/tiles/create-tile-item",
      DELETE_TILE_ITEM: "/api/customer-assurance/tiles/delete-tile-item",
      UPDATE_TILE_ITEM: "/api/customer-assurance/tiles/update-tile-item",
      UPDATE_TILE_ITEM_RANK:
        "/api/customer-assurance/tiles/update-tile-item-rank",
    },
    assurance_profile_updates: {
      CREATE_ASSURANCE_PROFILE_UPDATE:
        "/api/customer-assurance/updates/create-assurance-profile-update",
      UPDATE_ASSURANCE_PROFILE_UPDATE:
        "/api/customer-assurance/updates/update-assurance-profile-update",
      DELETE_ASSURANCE_PROFILE_UPDATE:
        "/api/customer-assurance/updates/delete-assurance-profile-update",
    },
    subprocessors: {
      CREATE_SUBPROCESSOR:
        "/api/customer-assurance/subprocessors/create-subprocessor",
      UPDATE_SUBPROCESSOR:
        "/api/customer-assurance/subprocessors/update-subprocessor",
      UPDATE_SUBPROCESSOR_RANK:
        "/api/customer-assurance/subprocessors/update-subprocessor-rank",
      DELETE_SUBPROCESSOR:
        "/api/customer-assurance/subprocessors/delete-subprocessor",
    },
    style_settings: {
      UPDATE_STYLE_SETTINGS:
        "/api/customer-assurance/style-settings/update-style-settings",
    },
    requestInformation: {
      APPROVE_INFORMATION_REQUEST:
        "/api/customer-assurance/request-information/approve-information-request",
      GET_INFORMATION_REQUESTS:
        "/api/customer-assurance/request-information/get-information-requests",
      REJECT_INFORMATION_REQUEST:
        "/api/customer-assurance/request-information/reject-information-request",
      UPDATE_SETTINGS:
        "/api/customer-assurance/request-information/update-settings",
      UPDATE_COMPANY_AGREEMENTS:
        "/api/customer-assurance/request-information/update-company-agreements",
      SEND_INFORMATION_REQUEST:
        "/api/customer-assurance/request-information/send-information-request",
      UPDATE_SUBTITLE:
        "/api/customer-assurance/request-information/update-request-info-subtitle",
      UPDATE_TITLE:
        "/api/customer-assurance/request-information/update-request-info-title",
      EXPORT_INFORMATION_REQUESTS_CSV:
        "/api/customer-assurance/request-information/export-information-requests-csv",
      REQUEST_CUSTOM_SECURITY_REVIEW:
        "/api/customer-assurance/request-information/request-custom-security-review",
    },
    logos: {
      CREATE_LOGO: "/api/customer-assurance/logos/create-logo",
      UPDATE_LOGO: "/api/customer-assurance/logos/update-logo",
      DELETE_LOGO: "/api/customer-assurance/logos/delete-logo",
    },
    privateDataRooms: {
      VERIFY_PRIVATE_DATA_ROOM: (id: string) =>
        `/api/customer-assurance/private-data-rooms/${id}/verify`,
      AUTHED_GET_PRIVATE_DATA_ROOM: (id: string) =>
        `/api/customer-assurance/private-data-rooms/${id}`,
      GENERATE_PRIVATE_DATA_ROOM_LINK: (id: string) =>
        `/api/customer-assurance/private-data-rooms/${id}/generate-data-room`,
      REVOKE_PRIVATE_DATA_ROOM_LINK: (id: string) =>
        `/api/customer-assurance/private-data-rooms/${id}/revoke-data-room-link`,
      GET_PRIVATE_DATA_ROOM_EVENTS: (id: string) =>
        `/api/customer-assurance/private-data-rooms/${id}/events`,
      APPROVE_PRIVATE_DATA_ROOM_LINK: (id: string) =>
        `/api/customer-assurance/private-data-rooms/${id}/approve`,
      REJECT_PRIVATE_DATA_ROOM_LINK: (id: string) =>
        `/api/customer-assurance/private-data-rooms/${id}/reject`,
    },
    magicLinks: {
      SEND_MAGIC_LINK_EMAIL:
        "/api/customer-assurance/magic-links/send-magic-link",
      AUTHENTICATE_MAGIC_LINK:
        "/api/customer-assurance/magic-links/authenticate-magic-link",
    },
    magicLinkAuth: {
      GET_PRIVATE_DATA_ROOM: (id: PrivateDataRoomLinkId) =>
        `/api/magic-link-auth/assurance/private-data-rooms/${id}`,
      DOWNLOAD_DOCUMENTS: (id: PrivateDataRoomLinkId) =>
        `/api/magic-link-auth/assurance/private-data-rooms/${id}/download-documents`,
      GET_NDA_DOCUMENT: (id: PrivateDataRoomLinkId) =>
        `/api/magic-link-auth/assurance/private-data-rooms/${id}/get-nda-document`,
      SIGN_NDA_DOCUMENT: (id: PrivateDataRoomLinkId) =>
        `/api/magic-link-auth/assurance/private-data-rooms/${id}/sign-nda-document`,
      CHECK_NDA_STATUS: (id: PrivateDataRoomLinkId) =>
        `/api/magic-link-auth/assurance/private-data-rooms/${id}/check-nda-status`,
      SEND_DOCUSIGN_ENVELOPE_PRIVATE_DATA_ROOM: (id: PrivateDataRoomLinkId) =>
        `/api/magic-link-auth/assurance/private-data-rooms/${id}/send-docusign-envelope-private-data-room`,
      GET_DOCUSIGN_ENVELOPE_PRIVATE_DATA_ROOM: (id: PrivateDataRoomLinkId) =>
        `/api/magic-link-auth/assurance/private-data-rooms/${id}/get-docusign-envelope`,
    },
    unauthedAccessDataRoom: {
      GET_PRIVATE_DATA_ROOM: (id: string) =>
        `/api/customer-assurance/unauthed-access-data-room/private-data-room/${id}`,
      DOWNLOAD_DOCUMENTS: (id: string) =>
        `/api/customer-assurance/unauthed-access-data-room/private-data-room/${id}/download-documents`,
      REFRESH_PRIVATE_DATA_ROOM_LINK: (id: string) =>
        `/api/customer-assurance/unauthed-access-data-room/private-data-room/${id}/refresh`,
    },
    nda: {
      DISCONNECT_DOCUSIGN: "api/customer-assurance/nda/disconnect-docusign",
      UPDATE_DOCSIGN_TEMPLATE_ID:
        "api/customer-assurance/nda/update-docusign-template-id",
      UPSERT_NDA: "/api/customer-assurance/nda/upsert-nda-document",
      DELETE_NDA: "/api/customer-assurance/nda/delete-nda-document",
      SETUP_IRONCLAD: "api/customer-assurance/nda/setup-ironclad",
    },
    docusign: {
      GET_DOCUSIGN_CONSENT_URL: (string: string) =>
        `api/customer-assurance/docusign/get-authorization-consent-url?profileVersionId=${string}`,
      GET_DOCUSIGN_ACCOUNT_TEMPLATES:
        "api/customer-assurance/docusign/get-docusign-account-templates",
    },
  },
  documentation: {
    GET_DOCS: "/api/documentation/docs",
  },
  copilot: {
    ANSWER: "/api/copilot/answer",
    GET_QUERY: "/api/copilot/get-query",
    GET_QUERIES: "/api/copilot/get-queries",
    UPDATE_QUERY: "/api/copilot/update-query",
    UPDATE_SETTINGS: "/api/copilot/update-settings",
    GET_SLACK_SETTINGS: "/api/copilot/get-slack-settings",
    DELETE_SLACK_SETTING: "/api/copilot/delete-slack-setting",
    CREATE_SLACK_SETTING: "/api/copilot/create-slack-setting",
    bulkAnswer: {
      NEW_REQUEST: "/api/copilot/bulk-answer/new-request",
      GET_REQUEST: (requestId: string) =>
        `/api/copilot/bulk-answer/${requestId}`,
      PROMOTE_REQUEST: "/api/copilot/bulk-answer/promote-request",
      DOWNLOAD_XLSX: (requestId: string) =>
        `/api/copilot/bulk-answer/${requestId}/download`,
      UPDATE_ANSWER: (queryId: string) =>
        `/api/copilot/bulk-answer/${queryId}/update-answer`,
      GET_MATCHING_SOURCES: "/api/copilot/bulk-answer/get-matching-sources",
    },
    entryGen: {
      NEW_REQUEST: "/api/copilot/entry-gen/new-request",
      GET_GENERATION_REQUESTS: "/api/copilot/entry-gen/get-requests",
      GET_ANSWERS: "/api/copilot/entry-gen/get-answers",
      ADD_TO_KNOWLEDGE_LIBRARY:
        "/api/copilot/entry-gen/add-to-knowledge-library",
      EXPORT_ANSWERS: "/api/copilot/entry-gen/export-answers",
      DOWNLOAD_DOCUMENT: "/api/copilot/entry-gen/download-document",
    },
  },
  dashboard: {
    GET_QUESTIONNAIRE_SENDERS: "/api/dashboard/get-questionnaire-senders",
    GET_QUESTIONNAIRE_FORMATS: "/api/dashboard/get-questionnaire-formats",
    GET_QUESTIONNAIRES: "/api/dashboard/get-questionnaires",
    GET_QUESTIONNAIRE_THREAD: "/api/dashboard/get-questionnaire-thread",
    EXPORT_CSV: "/api/dashboard/export-questionnaires-csv",
  },
  homepage: {
    GET_NEEDS_ACTION_COUNTS: "/api/homepage/get-needs-action-counts",
    GET_HOMEPAGE_ANALYTICS: "/api/homepage/get-homepage-analytics",
  },
  missionControl: {
    GET_THREAD: "/api/mission-control/threads/get-thread",
    GET_THREADS: "/api/mission-control/threads/get-threads",
    SET_COMPANY: "/api/mission-control/threads/set-company",
    ASSIGN_USER: "/api/mission-control/threads/assign-user",
    UPDATE_SEEN: "/api/mission-control/threads/update-seen",
    UPDATE_BOOKMARKED: "/api/mission-control/threads/update-bookmarked",
    UPDATE_LABELS: "/api/mission-control/threads/update-labels",
    SET_INBOX: "/api/mission-control/threads/set-inbox",
    ARCHIVE: "/api/mission-control/threads/archive",
    DEFAULT_RECIPIENTS: "/api/mission-control/threads/get-default-recipients",
    CREATE_COMMENT: "/api/mission-control/comments/create-comment",
    DELETE_COMMENT: "/api/mission-control/comments/delete-comment",
    EDIT_COMMENT: "/api/mission-control/comments/edit-comment",
    SEARCH_CONTACTS: "/api/mission-control/contacts/search-contacts",
    DOWNLOAD_ATTACHMENT: "/api/mission-control/attachments/download-attachment",
    SCHEDULE_SEND_DRAFT: "/api/mission-control/drafts/schedule-send-draft",
    UNDO_SEND_DRAFT: "/api/mission-control/drafts/undo-send-draft",
    CREATE_GMAIL_DRAFT: "/api/mission-control/drafts/create-gmail-draft",
    POST_SIGNED_UPLOAD_URLS:
      "/api/mission-control/drafts/post-process-after-gmail-file-uploads",
    UPDATE_GMAIL_DRAFT: "/api/mission-control/drafts/update-gmail-draft",
    TAKE_OVER_DRAFT: "/api/mission-control/drafts/take-over-draft",
    DELETE_DRAFT: "/api/mission-control/drafts/delete-draft",
    BULK_UPLOAD_ATTACHMENTS:
      "/api/mission-control/drafts/bulk-upload-attachments",
    BULK_DELETE_ATTACHMENTS:
      "/api/mission-control/drafts/bulk-delete-attachments",
    PREVIEW_GMAIL_IMAGE: "/api/mission-control/drafts/preview-gmail-image",
    CREATE_LABEL: "/api/mission-control/labels/create-label",
    GET_LABELS: "/api/mission-control/labels/get-labels",
    EDIT_LABEL: "/api/mission-control/labels/edit-label",
    DELETE_LABEL: "/api/mission-control/labels/delete-label",

    GET_TEMPLATES: "/api/mission-control/template-folders/get-templates",
    DELETE_TEMPLATE_FOLDER:
      "/api/mission-control/template-folders/delete-template-folder",
    CREATE_TEMPLATE_FOLDER:
      "/api/mission-control/template-folders/create-template-folder",
    EDIT_TEMPLATE_FOLDER:
      "/api/mission-control/template-folders/edit-template-folder",
    CREATE_TEMPLATE: "/api/mission-control/templates/create-template",
    EDIT_TEMPLATE: "/api/mission-control/templates/edit-template",
    DELETE_TEMPLATE: "/api/mission-control/templates/delete-template",

    GET_INBOX_METADATA: "/api/mission-control/inboxes/metadata",
    UPDATE_INBOX_OPENED_TIMESTAMP:
      "/api/mission-control/inboxes/update-opened-timestamp",
    CHECK_OAUTH: "/api/mission-control/oauth/check",
  },
  notifications: {
    GET_NOTIFICATIONS: "/api/notifications/get-notifications",
    GET_NOTIFICATION_SETTINGS: "/api/notifications/get-notification-settings",
    GET_UNREAD_NOTIFICATIONS: "/api/notifications/get-unread-notifications",
    MARK_ALL_READ: "/api/notifications/mark-all-read",
    MARK_READ: "/api/notifications/mark-read",
    UPDATE_NOTIFICATION_SETTINGS:
      "/api/notifications/update-notification-settings",
  },
  questionnaires: {
    GET_QUESTIONNAIRE: "/api/questionnaires/get-questionnaire",
    GET_QUESTIONNAIRES: "/api/questionnaires/get-questionnaires",
    GET_COMPLETED_QUESTIONNAIRES:
      "/api/questionnaires/get-completed-questionnaires",
    UPDATE_URL: "/api/questionnaires/update-url",
    UPLOAD_PRISM_SHEET: "/api/questionnaires/upload-prism-sheet",
    UPDATE_PRISM_ENTRIES: "/api/questionnaires/update-prism-entries",
    CREATE_PRISM_ENTRIES: "/api/questionnaires/create-prism-entries",
    CLONE_PRISM_ENTRY: "/api/questionnaires/clone-prism-entry",
    DELETE_PRISM_ENTRY: "/api/questionnaires/delete-prism-entry",
    MOVE_TO_AL_PHASE: "/api/questionnaires/move-to-al-phase",
  },
  documents: {
    BULK_UPLOAD_DOCUMENTS: "/api/documents/bulk-upload-documents",
    BULK_ADD_REVIEWS: "/api/documents/bulk-add-reviews",
    BULK_DOWNLOAD_DOCUMENTS: "/api/documents/bulk-download-documents",
    BULK_UPDATE_REVIEW_CYCLE: "/api/documents/bulk-update-review-cycle",
    BULK_UPDATE_OWNERS: "/api/documents/bulk-update-owners",
    DELETE_DOCUMENT: "/api/documents/delete-document",
    GET_DOCUMENTS: "/api/documents/get-documents",
    EDIT_DOCUMENT: "/api/documents/edit-document",
    GET_DOCUMENT_METADATA: "/api/documents/get-document-metadata",
    DOWNLOAD_DOCUMENT: "/api/documents/download-document",
    CREATE_DOCUMENT_VERSION: "/api/documents/create-document-version",
    DELETE_DOCUMENT_VERSION: "/api/documents/delete-document-version",
    BULK_DELETE_DOCUMENTS: "/api/documents/bulk-delete-documents",
  },
  suggestions: {
    APPROVE_EDIT: "/api/suggestions/approve-edit",
    GET_SUGGESTED_EDITS: "/api/suggestions/get-suggested-edits",
    REJECT_EDIT: "/api/suggestions/reject-edit",
    UPDATE_QUESTION: "/api/suggestions/update-question",
  },
  userManagement: {
    ADD_USER: "/api/user-management/add-user",
    GET_USER: (id: UserId) => `/api/user-management/user/${id}`,
    CREATE_OR_UPDATE_ACCESS_GROUP:
      "/api/user-management/create-or-update-access-group",
    DELETE_ACCESS_GROUP: "/api/user-management/delete-access-group",
    REMOVE_USER: "/api/user-management/remove-user",
    UPDATE_ACTIVE_STATUS: "/api/user-management/update-active-status",
    RESET_MFA: "/api/user-management/reset-mfa",
    GET_DSYNC_GROUPS: "/api/user-management/get-dsync-groups",
    GET_ACCESS_GROUPS: "/api/user-management/get-access-groups",
    GET_ACCESS_GROUP_USERS: (id: AccessGroupId) =>
      `/api/user-management/access-group/${id}/users`,
    GET_USERS: "/api/user-management/get-users",
    GET_USERS_WITH_PERMISSION: "/api/user-management/get-users-with-permission",
    SSO_ADMIN_PORTAL: "/api/user-management/generate-sso-admin-portal-link",
    ADD_ACCESS_GROUP_USERS: (id: AccessGroupId) =>
      `/api/user-management/access-group/${id}/add-users`,
    REMOVE_ACCESS_GROUP_USERS: (id: AccessGroupId) =>
      `api/user-management/access-group/${id}/remove-users`,
  },
  validation: {
    VALIDATE_QUESTIONNAIRE: "/api/validation/validate-questionnaire",
  },
  vendorAssess: {
    changeDetection: {
      REGISTER_URL: "/api/vendor-assess/change-detection/register-url",
      GET_URLS: "/api/vendor-assess/change-detection/get-urls",
      GET_CHANGED_DATES:
        "/api/vendor-assess/change-detection/get-changed-dates",
      DELETE_URL: "/api/vendor-assess/change-detection/delete-watched-url",
      GET_URL_SNAPSHOTS:
        "/api/vendor-assess/change-detection/get-url-snapshots",
    },
    REQUEST_INFO: "/api/vendor-assess/request-info",
    CREATE_ASSESSMENT: "/api/vendor-assess/create-assessment",
    GET_ASSESSMENTS: "/api/vendor-assess/get-assessments",
    GET_ASSESSMENT: "/api/vendor-assess/get-assessment",
    UPDATE_ASSESSMENT: "/api/vendor-assess/update-assessment",
    GET_ASSESSMENTS_STATISTICS: "/api/vendor-assess/get-assessments-statistics",
  },
  questionnaireParsing: {
    DIFF_QUESTIONNAIRES: "/api/questionnaire-parsing/diff-questionnaires",
    PARSE_XLSX: "/api/questionnaire-parsing/parse-xlsx",
  },
  scrubbing: {
    SCRUB: "/api/scrubbing/scrub-entities",
  },
  altitudeReports: {
    UPLOAD_REPORT: "/api/altitude-reports/upload-report",
    UPLOAD_SAMPLE_REPORT: "/api/altitude-reports/upload-sample-report",
    REQUEST_REPORT: "/api/altitude-reports/request-report",
    GET_REPORT_REQUESTS: "/api/altitude-reports/get-report-requests",
    GET_SAMPLE_REPORTS: "/api/altitude-reports/get-sample-reports",
    POST_PROCESS_REPORT_AFTER_SIGNED_UPLOAD:
      "/api/altitude-reports/post-process-report-after-signed-upload",
    DOWNLOAD_REPORT: (reportId: ReportId) =>
      `/api/altitude-reports/download-report/${reportId}`,
    ACCEPT_REPORT_REQUEST: "/api/altitude-reports/accept-report-request",
    CANCEL_REPORT_REQUEST: "/api/altitude-reports/cancel-report-request",
    DELETE_REPORT: "/api/altitude-reports/delete-report",
    DELETE_CUSTOMER_REPORT: "/api/altitude-reports/delete-customer-report",
  },
  searchMetrics: {
    COLLECT_STATS: "/api/search-metrics/collect-stats",
  },
  pendingQuestions: {
    GET_PENDING_QUESTIONS: "/api/pending-questions/get-pending-questions",
    SAVE_ANSWER_TO_PENDING_QUESTION:
      "/api/pending-questions/save-answer-to-pending-question",
    UPLOAD_ANSWER_TO_KL: "/api/pending-questions/upload-answer-to-kl",
    UPLOAD_SINGLE_PENDING_QUESTION:
      "/api/pending-questions/upload-single-question",
    BULK_UPLOAD: "/api/pending-questions/bulk-upload-pending-questions",
    ASSIGN_USER: "/api/pending-questions/assign-user",
    EDIT_QUESTION: "/api/pending-questions/edit-question",
  },
  referrals: {
    GET_REFERRALS: "/api/referrals/get-referrals",
    SUBMIT_REFERRAL: "/api/referrals/submit-referral",
  },
  slackbot: {
    LINK_SLACK_INSTALLATION: "/api/slackbot/link-slack-installation",
    GET_SLACKBOT_COMPANY_SETTINGS:
      "/api/slackbot/get-slackbot-company-settings",
    UPDATE_SLACKBOT_COMPANY_SETTINGS:
      "/api/slackbot/update-slackbot-company-settings",
    GET_SLACKBOT_WORKSAPCES: "/api/slackbot/get-slackbot-workspaces",
  },
  utils: {
    GET_SIGNED_UPLOAD_URL: "/api/utils/get-signed-upload-url",
    GET_SIGNED_UPLOAD_URLS: "/api/utils/get-signed-upload-urls",
  },
};

export const UserFeedbackSurveyLink = {
  FOR_INTERNAL_USER: "https://airtable.com/shrn3kIC6WRpMvVrF",
  FOR_EXTERNAL_USER: "https://forms.gle/q12q5od4MknrZsc8A",
};

export const answerList = ["Yes", "No", "N/A", "Other"];
export const categories = [
  "Acceptable Use Policy",
  "Access Control",
  "Anti-virus and Malware Control",
  "API Security",
  "Application",
  "Application Security",
  "Artificial Intelligence",
  "Asset Management",
  "Audit Assurance",
  "Backup Policy and Procedures",
  "Business Continuity and Disaster Recovery",
  "Change Control and Configuration Management",
  "Data Governance and Classification",
  "Data Retention and Deletion",
  "Data Security",
  "Email Security",
  "Encryption and Key Management",
  "ESG",
  "Hosting",
  "Human Resources",
  "Identity and Access Management (IAM)",
  "Incident Management",
  "Information Security",
  "Integration",
  "Interoperability and Portability Measures",
  "Legal",
  "Logging, Monitoring and Audit Trail",
  "Mobile Device Security",
  "Network Security",
  "Organization",
  "Password Policy and authentication procedures",
  "Patch Management",
  "Penetration Testing",
  "Physical Security and Environmental Controls",
  "Privacy and Compliance",
  "Risk Management",
  "Security Awareness",
  "Server Security",
  "Session Management",
  "Software Development Life Cycle",
  "Supply Chain/ Vendor Management",
  "Vulnerability and Threat Management",
  "Wireless Security",
];

export const categoryRibbonColor = "#10239e";

export const docxExtensions = [".docx", ".docm"];
export const xlsxExtensions = [".xlsx", ".xlsm"];
export const otherExtensions = [".pdf", ".pptx", ".pptm"];

// List of stop words from https://simple.wiktionary.org/wiki/Wiktionary:Basic_English_ordered_wordlist
// concatenated with the ones from https://gist.github.com/sebleier/554280
export const stopWords = [
  "come",
  "get",
  "give",
  "go",
  "keep",
  "let",
  "make",
  "put",
  "seem",
  "take",
  "be",
  "do",
  "have",
  "say",
  "see",
  "send",
  "may",
  "will",
  "about",
  "across",
  "after",
  "against",
  "among",
  "at",
  "before",
  "between",
  "by",
  "down",
  "from",
  "in",
  "off",
  "on",
  "over",
  "through",
  "to",
  "under",
  "up",
  "with",
  "as",
  "for",
  "of",
  "till",
  "than",
  "a",
  "the",
  "all",
  "any",
  "every",
  "little",
  "much",
  "no",
  "other",
  "some",
  "such",
  "that",
  "this",
  "i",
  "he",
  "you",
  "who",
  "and",
  "because",
  "but",
  "or",
  "if",
  "though",
  "while",
  "what",
  "how",
  "when",
  "where",
  "why",
  "again",
  "ever",
  "far",
  "forward",
  "here",
  "near",
  "now",
  "out",
  "still",
  "then",
  "there",
  "together",
  "well",
  "almost",
  "enough",
  "even",
  "not",
  "only",
  "quite",
  "so",
  "very",
  "tomorrow",
  "yesterday",
  "north",
  "south",
  "east",
  "west",
  "please",
  "yes",
  "me",
  "my",
  "myself",
  "we",
  "our",
  "ours",
  "ourselves",
  "your",
  "yours",
  "yourself",
  "yourselves",
  "him",
  "his",
  "himself",
  "she",
  "her",
  "hers",
  "herself",
  "it",
  "its",
  "itself",
  "they",
  "them",
  "their",
  "theirs",
  "themselves",
  "which",
  "whom",
  "these",
  "those",
  "am",
  "is",
  "are",
  "was",
  "were",
  "been",
  "being",
  "has",
  "had",
  "having",
  "does",
  "did",
  "doing",
  "an",
  "until",
  "into",
  "during",
  "above",
  "below",
  "further",
  "once",
  "both",
  "each",
  "few",
  "more",
  "most",
  "nor",
  "own",
  "same",
  "too",
  "s",
  "t",
  "can",
  "just",
  "don",
  "should",
];

export const mainColors = {
  lightBlue: "#c3d6e2ff",
  blue: "#34538aff",
  bone: "#f0ede6ff",
};

export const accentColors = {
  iceBlue: "#C7F9FC",
  lightTeal: "#45B6BD",
  salmon: "#ED9292",
  lightGreen: "#A0EBA8",
  aqua: "#29CAEE",
  gold: "#FCC53B",
  palePink: "#E7BDBF",
  gray: "#C4C4C4",
  whiteYellow: "#FCF3E0",
  paleYellow: "#EED990",
  darkTeal: "#147F96",
  powderBlue: "#6FB5C6",
};

export const darkAccentColors = [
  accentColors.lightTeal,
  accentColors.salmon,
  accentColors.aqua,
  accentColors.gold,
  accentColors.darkTeal,
  accentColors.powderBlue,
];

export const acceptedFileExtensionsToDisplayPreview = [
  "png",
  "jpg",
  "jpeg",
  "avif",
  ".svg",
  ".webp",
  ".gif",
];

export enum LoginPagePath {
  Login = "/login",
  EnrollMFA = "/enroll-mfa",
  ValidateMFA = "/validate-mfa",
  ResetPassword = "/reset-password",
  Register = "/register",
}

export const CAN_CANCEL_TASK_ON_STATUSES: DashboardQuestionnaireStatus[] = [
  "submitted",
  "undergoingCompletion",
  "qaCheck",
  "unknown",
];

// RTK Query cache timeout in seconds
// We are using 8 minutes because pre-signed image urls expire after 10
export const CACHE_TIMEOUT_8_MIN_IN_SECONDS = 480;

export enum InformationRequestTabKey {
  PENDING = "pending",
  PROCESSED = "processed",
}
