import { Column } from "@ant-design/plots";
import { Skeleton } from "antd";
import { useMemo } from "react";
import { useGetVendorAssessCompletedByMonthAnalyticsQuery } from "../../../features/API/AnalyticsDashboard";
import { VendorAssessmentCompletedStat } from "../../../features/API/AnalyticsDashboard/types";
import { getAnalyticsMessageComponent } from "../utils";
import { filterData } from "../utils";
import { VendorAssessChartProps } from "./VendorAssessAnalyticsContainer";

/**
 * Component that displays metrics for the time saved as a column chart
 * @param {VendorAssessTimeSavedColumnChartProps} props - Component props
 */

interface VendorAssessTimeSavedProps extends VendorAssessChartProps {
  timeSavedInHrs: number;
}
const VendorAssessTimeSavedColumnChart = ({
  companyId,
  activeFilter,
  theme,
  height,
  width,
  timeSavedInHrs,
}: VendorAssessTimeSavedProps): JSX.Element => {
  const {
    data: timeSavedStatsData,
    isLoading: statsLoading,
    isError: statsError,
  } = useGetVendorAssessCompletedByMonthAnalyticsQuery({
    companyId,
  });

  const filteredData = useMemo(() => {
    if (!timeSavedStatsData?.data?.vendorAssessCompletedStats) return [];

    const baseData = filterData(
      timeSavedStatsData?.data?.vendorAssessCompletedStats,
      activeFilter,
    ) as VendorAssessmentCompletedStat[];

    // If there's a multiplier value, apply it to the time saved hours
    if (timeSavedInHrs) {
      return baseData.map((item) => ({
        ...item,
        timeSavedInHrs: item.assessmentsCompleted * timeSavedInHrs,
      }));
    }

    return baseData;
  }, [
    timeSavedStatsData?.data?.vendorAssessCompletedStats,
    timeSavedInHrs,
    activeFilter,
  ]);

  if (statsLoading) {
    return <Skeleton active />;
  }

  if (statsError) {
    return getAnalyticsMessageComponent({
      type: "error",
      title: "Failed to Load Time Saved Statistics",
      subTitle: "An unexpected error occurred while fetching Time Saved data",
    });
  }

  if (
    !timeSavedStatsData ||
    !timeSavedStatsData?.data?.vendorAssessCompletedStats ||
    timeSavedStatsData?.data?.vendorAssessCompletedStats.every(
      (stat) => stat.assessmentsCompleted === 0,
    )
  ) {
    return getAnalyticsMessageComponent({
      type: "info",
      title: "No Time Saved Data",
      subTitle: "There are no Time Saved statistics available",
    });
  }

  const chartConfig = {
    theme,
    data: filteredData,
    xField: "date",
    yField: "timeSavedInHrs",
    padding: "auto",
    height,
    width,
    xAxis: {
      label: {
        autoRotate: true,
      },
    },
    tooltip: {
      items: [{ name: "Time Saved", channel: "y" }],
    },
    axis: {
      y: { title: "Hours" },
      x: { title: "Month" },
    },
    style: {
      maxWidth: 20,
    },
  };

  return <Column {...chartConfig} />;
};

export default VendorAssessTimeSavedColumnChart;
