import { ColumnType } from "antd/es/table";
import dayjs from "dayjs";
import { HiCheckCircle, HiDotsCircleHorizontal } from "react-icons/hi";
import { AssuranceDocumentResponse } from "../../app_frontend/src/features/AssuranceProfile/autoGeneratedTypes";
import { AssuranceDocumentGroupWithDocuments } from "../../app_frontend/src/features/AssuranceProfile/generatedTypes/documentTypes";
import {
  CertificationSelectionStatus,
  FilterType,
  GroupedDocuments,
  ISODateStr,
  SortDirections,
} from "./types/assuranceProfile";
import styles from "./utils.module.scss";

export const statusIcon = (
  status: CertificationSelectionStatus,
): JSX.Element => {
  switch (status) {
    case "completed":
      return <HiCheckCircle color="#4BA654" />;
    case "inProgress":
      return <HiDotsCircleHorizontal color="gold" />;
    default:
      return <></>;
  }
};

export const publishedDateString = (d: ISODateStr | null): string => {
  if (d === null) {
    return "Unpublished";
  }

  return dayjs(d).format("MMMM DD, YYYY");
};

export const ensureExhaustive = (param: never): never => {
  throw new Error(`should not reach here: ${param}`);
};

export const capitalize = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const convertSortOrder = (
  order: "ascend" | "descend",
): SortDirections => {
  switch (order) {
    case "ascend":
      return SortDirections.ascending;
    case "descend":
      return SortDirections.descending;
    default:
      return ensureExhaustive(order);
  }
};

export const getDocumentsArrangedByGroup = (
  documents: AssuranceDocumentResponse[],
) => {
  const documentsArrangedByGroup: AssuranceDocumentGroupWithDocuments[] = [];
  const documentsWithNoGroup: AssuranceDocumentResponse[] = [];

  documents.forEach((doc) => {
    const group = doc.relationships.group;

    if (!group) {
      documentsWithNoGroup.push(doc);
      return;
    }

    const existingGroup = documentsArrangedByGroup.find(
      (g) => g.id === group?.id,
    );

    if (!existingGroup) {
      documentsArrangedByGroup.push({
        ...group,
        documents: [doc],
      });
      return;
    }

    existingGroup.documents.push(doc);
  });

  return {
    documentsArrangedByGroup,
    documentsWithNoGroup,
  };
};

export const getGroupedDocumentsSelectOption = (
  documentsArrangedByGroup: AssuranceDocumentGroupWithDocuments[],
  documentsWithNoGroup: AssuranceDocumentResponse[],
) => {
  const groupedDocuments: GroupedDocuments = [];

  documentsArrangedByGroup.forEach((group) => {
    const title = group.attributes.title;
    groupedDocuments.push({
      label: title,
      options: group.documents.map((doc) => ({
        label: doc.attributes.title,
        value: doc.id,
      })),
    });
  });

  if (documentsWithNoGroup.length === 0) {
    return groupedDocuments;
  }
  groupedDocuments.push({
    label: "Ungrouped",
    options: documentsWithNoGroup.map((doc) => ({
      label: doc.attributes.title,
      value: doc.id,
    })),
  });

  return groupedDocuments;
};

export function getFilteredDocuments(
  documents: AssuranceDocumentResponse[],
  filter: FilterType,
): AssuranceDocumentResponse[] {
  switch (filter) {
    case "privateDocuments":
      return documents.filter((document) => !document.attributes.isPublic);
    case "publicDocuments":
      return documents.filter((document) => document.attributes.isPublic);
    default:
      return documents;
  }
}

export const getSearchHighlightStyle = (primaryColor: string): string => {
  return `background-color: ${primaryColor}; padding: 0.2rem; border-radius: 0.25rem; color: white`;
};

/**
 * Creates a function that renders an expandable row for a Table component.
 * Don't add dataIndex if column has a custom render method which will be used to render the column data instead of values from dataIndex.
 * @param {T} record The record to render.
 * @param {ColumnType<T>[]} expandedColumns The columns to render in the expanded row.
 */
export function createExpandableRowRender<T>(
  record: T,
  expandedColumns: ColumnType<T>[],
) {
  return (
    <div className={styles.ExpandedRow}>
      {expandedColumns.map((column, index) => {
        // biome-ignore lint/suspicious/noExplicitAny: Using 'any' here allows dynamic traversal of nested objects with unknown structure
        let value: any = record;
        // Handle nested dataIndex as array
        if (Array.isArray(column.dataIndex)) {
          for (const key of column.dataIndex) {
            value = value?.[key];
          }
        } else if (typeof column.dataIndex === "string") {
          value = value?.[column.dataIndex];
        }

        return (
          <div key={column.key} className={styles.ExpandedRowItem}>
            <strong>{column.title?.toString()}:</strong>{" "}
            {column.render
              ? column.render(value, record, index)
              : (value?.toString() ?? "")}
          </div>
        );
      })}
    </div>
  );
}
