import { Column } from "@ant-design/plots";
import { Result, Skeleton } from "antd";
import { useGetAssuranceProfileInformationRequestsAnalyticsQuery } from "../../../features/API/AnalyticsDashboard";
import { AssuranceProfileInformationRequestStats } from "../../../features/API/AnalyticsDashboard/types";
import { filterData } from "../utils";
import { AssuranceProfileChartProps } from "./AssuranceProfileAnalyticsContainer";

const InformationRequestChart = ({
  selectedProfileIds,
  activeFilter,
  theme,
  height,
  width,
}: AssuranceProfileChartProps): JSX.Element => {
  const {
    data: requestStats,
    isLoading: isRequestStatsLoading,
    error: isRequestStatsError,
  } = useGetAssuranceProfileInformationRequestsAnalyticsQuery({
    profileIds: selectedProfileIds,
  });

  if (isRequestStatsLoading) {
    return <Skeleton active />;
  }

  if (isRequestStatsError) {
    return (
      <Result
        status="error"
        title="Failed to Load Information Request Statistics"
        subTitle="An unexpected error occurred while fetching information request data"
      />
    );
  }

  if (
    !requestStats ||
    !requestStats.data ||
    requestStats.data.every((stat) => stat.number === 0)
  ) {
    return (
      <Result
        status="info"
        title="No Information Request Data"
        subTitle="There are no information request statistics available for the selected profiles"
      />
    );
  }

  const config = {
    theme,
    data: filterData(
      requestStats.data,
      activeFilter,
      false,
      true,
    ) as AssuranceProfileInformationRequestStats[],
    xField: "date",
    yField: "number",
    stack: true,
    colorField: "type",
    height,
    width,
    axis: {
      y: { title: "Information Requests" },
      x: { title: "Month" },
    },
    style: {
      maxWidth: 20,
    },
  };

  return <Column {...config} />;
};

export default InformationRequestChart;
