import {
  InfoCircleOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Input, Segmented, Tooltip, Typography } from "antd";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { BaseChartProps, DateFilterEnum } from "..";
import { ProductId } from "../../../features/API/types";
import { ThemeType } from "../utils";
import AnnualRevenuePieChart from "./AnnualRevenuePieChart";
import CompletionPercentageLineChart from "./CompletionPercentageLineChart";
import styles from "./ContainerBase.module.scss";
import CopilotSearchColumnChart from "./CopilotSearchColumnChart";
import KLSearchColumnChart from "./KLSearchColumnChart";
import KLUpdatesColumnChart from "./KLUpdatesColumnChart";
import PendingQuestionsColumnChart from "./PendingQuestionsColumnChart";
import tabStyles from "./TabsStyles.module.scss";
import TimeSavedColumnChart from "./TimeSavedColumnChart";

enum TabKey {
  TimeSaved = "timeSaved",
  CompletionPercentage = "completionPercentage",
  PendingQuestions = "pendingQuestions",
  SearchesOnCoPilot = "searchesOnCoPilot",
  SearchesOnKL = "searchesOnKL",
  KLUpdate = "klUpdate",
  AnnualRevenue = "annualRevenue",
}

export interface ConceirgeChartProps extends BaseChartProps {
  productId: ProductId;
}

interface ConciergeAnalyticsContainerProps {
  productId: ProductId;
  activeFilter: DateFilterEnum;
  theme: ThemeType;
  bigQueryAnalyticsSearchEnabled?: boolean;
  height: number;
  width: number;
}

const ConciergeAnalyticsContainer = ({
  productId,
  activeFilter,
  theme,
  bigQueryAnalyticsSearchEnabled,
  height,
  width,
}: ConciergeAnalyticsContainerProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<TabKey>(TabKey.TimeSaved);
  const [timeSavedInputValue, setTimeSavedInputValue] = useState<number>(2);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    const container = scrollContainerRef.current;
    if (container) {
      setShowLeftArrow(container.scrollLeft > 0);
      setShowRightArrow(
        container.scrollLeft < container.scrollWidth - container.clientWidth,
      );
    }
  };

  const scroll = (direction: "left" | "right") => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = container.clientWidth * 0.5;
      container.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      handleScroll();
      container.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleScroll);

      return () => {
        container.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleScroll);
      };
    }
  }, []);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "-") {
      e.preventDefault();
    }
    const input = e.target as HTMLInputElement;
    const value = parseFloat(input.value);
    if (!isNaN(value) && value > 0) {
      setTimeSavedInputValue(value);
      input.setCustomValidity("");
    } else {
      input.setCustomValidity("Please enter a number greater than 0");
      input.reportValidity();
    }
  };

  const options = [
    { label: "Time Saved", value: TabKey.TimeSaved },
    { label: "Completion Percentage", value: TabKey.CompletionPercentage },
    { label: "Pending Questions", value: TabKey.PendingQuestions },
    ...(bigQueryAnalyticsSearchEnabled
      ? [
          { label: "Searches on Copilot", value: TabKey.SearchesOnCoPilot },
          {
            label: "Searches on Knowledge Library",
            value: TabKey.SearchesOnKL,
          },
        ]
      : []),
    { label: "Knowledge Library Updates", value: TabKey.KLUpdate },
    { label: "Annual Revenue", value: TabKey.AnnualRevenue },
  ];

  const renderContent = () => {
    switch (selectedTab) {
      case TabKey.TimeSaved:
        return (
          <div className={styles.TimeSavedChartContainer}>
            <div className={styles.TimeSavedHeader}>
              <div className={styles.TimeSavedHeaderInputContainer}>
                <div className={styles.TimeSavedHeaderInputTitleContainer}>
                  <Typography.Text className={styles.TimeSavedHeaderInputTitle}>
                    Estimated hours
                  </Typography.Text>
                  <Tooltip
                    title="Estimated time (in hours) it would take for you to complete 100 security questions."
                    placement="topRight"
                  >
                    <InfoCircleOutlined
                      className={styles.TimeSavedHeaderIcon}
                    />
                  </Tooltip>
                </div>
                <div className={styles.TimeSavedHeaderInput}>
                  <Input
                    defaultValue={timeSavedInputValue}
                    type="number"
                    min={1}
                    max={1000}
                    placeholder="Hrs"
                    onKeyUp={handleKeyPress}
                  />
                </div>
              </div>
            </div>
            <div className={styles.TimeSavedColumnChart}>
              <TimeSavedColumnChart
                productId={productId}
                activeFilter={activeFilter}
                timePerHundredQuestions={timeSavedInputValue}
                theme={theme}
                height={height}
                width={width}
              />
            </div>
          </div>
        );
      case TabKey.CompletionPercentage:
        return (
          <div className={styles.AnalyticsChart}>
            <CompletionPercentageLineChart
              productId={productId}
              activeFilter={activeFilter}
              theme={theme}
              height={height}
              width={width}
            />
          </div>
        );
      case TabKey.PendingQuestions:
        return (
          <div className={styles.AnalyticsChart}>
            <PendingQuestionsColumnChart
              productId={productId}
              activeFilter={activeFilter}
              theme={theme}
              height={height}
              width={width}
            />
          </div>
        );
      case TabKey.SearchesOnCoPilot:
        return bigQueryAnalyticsSearchEnabled ? (
          <div className={styles.AnalyticsChart}>
            <CopilotSearchColumnChart
              productId={productId}
              activeFilter={activeFilter}
              theme={theme}
              height={height}
              width={width}
            />
          </div>
        ) : null;
      case TabKey.SearchesOnKL:
        return bigQueryAnalyticsSearchEnabled ? (
          <div className={styles.AnalyticsChart}>
            <KLSearchColumnChart
              productId={productId}
              activeFilter={activeFilter}
              theme={theme}
              height={height}
              width={width}
            />
          </div>
        ) : null;
      case TabKey.KLUpdate:
        return (
          <div className={styles.AnalyticsChart}>
            <KLUpdatesColumnChart
              productId={productId}
              activeFilter={activeFilter}
              theme={theme}
              height={height}
              width={width}
            />
          </div>
        );
      case TabKey.AnnualRevenue:
        return (
          <div className={styles.AnalyticsChart}>
            <AnnualRevenuePieChart
              productId={productId}
              activeFilter={activeFilter}
              theme={theme}
              height={height}
              width={width}
            />
          </div>
        );
    }
  };

  return (
    <div className={clsx(styles.AnalyticsContainer, tabStyles.TabsContainer)}>
      <div className={tabStyles.SegmentedTabs}>
        <button
          className={clsx(tabStyles.ScrollArrow, tabStyles.Left, {
            [tabStyles.Hidden]: !showLeftArrow,
          })}
          onClick={() => scroll("left")}
        >
          <LeftOutlined />
        </button>
        <div className={tabStyles.ScrollContainer} ref={scrollContainerRef}>
          <Segmented
            options={options}
            value={selectedTab}
            onChange={(value) => setSelectedTab(value as TabKey)}
          />
        </div>
        <button
          className={clsx(tabStyles.ScrollArrow, tabStyles.Right, {
            [tabStyles.Hidden]: !showRightArrow,
          })}
          onClick={() => scroll("right")}
        >
          <RightOutlined />
        </button>
      </div>
      {renderContent()}
    </div>
  );
};

export default ConciergeAnalyticsContainer;
