import { Column } from "@ant-design/plots";
import { Result, Skeleton } from "antd";
import { useMemo } from "react";
import { useGetAssuranceProfileDocumentsDownloadAnalyticsQuery } from "../../../features/API/AnalyticsDashboard";
import {
  DocumentDownloads,
  DocumentDownloadsPerMonth,
} from "../../../features/API/AnalyticsDashboard/types";
import { filterData } from "../utils";
import { AssuranceProfileChartProps } from "./AssuranceProfileAnalyticsContainer";
import styles from "./DocumentsDownloadChart.module.scss";

const DocumentDownloadChart = ({
  selectedProfileIds,
  activeFilter,
  theme,
  height,
  width,
}: AssuranceProfileChartProps): JSX.Element => {
  const {
    data: downloadStats,
    isLoading,
    error,
  } = useGetAssuranceProfileDocumentsDownloadAnalyticsQuery({
    profileIds: selectedProfileIds,
  });

  const transformData = useMemo(() => {
    if (!downloadStats?.data) return [];

    const filteredData = filterData(
      downloadStats.data.documentDownloadsPerMonth,
      activeFilter,
    ) as DocumentDownloadsPerMonth[];

    return filteredData.flatMap((monthStat) =>
      monthStat.documents.map((doc: DocumentDownloads) => ({
        month: monthStat.month,
        document: doc.fileName === "" ? "No Downloads" : doc.fileName,
        downloads: doc.totalDownloads,
      })),
    );
  }, [downloadStats?.data, activeFilter]);

  const chartConfig = useMemo(
    () => ({
      theme,
      data: transformData,
      xField: "month",
      yField: "downloads",
      colorField: "document",
      isGroup: true,
      height,
      width,
      columnStyle: {
        radius: [4, 4, 0, 0],
      },
      stack: {
        groupBy: ["x", "document"],
        series: true,
      },
      style: {
        maxWidth: 20,
      },
    }),
    [transformData, theme, height, width],
  );

  if (isLoading) return <Skeleton active />;
  if (error) {
    return (
      <Result
        status="error"
        title="Failed to Load Document Download Statistics"
        subTitle="An unexpected error occurred while fetching document download data"
      />
    );
  }

  if (
    !downloadStats?.data ||
    downloadStats.data.documentDownloadsPerMonth.every(
      (stat) => stat.documents.length === 0,
    )
  ) {
    return (
      <Result
        status="info"
        title="No Download Data"
        subTitle="There are no document download statistics available for the selected profiles"
      />
    );
  }

  return (
    <div className={styles.DocumentDownloadChartContainer}>
      <div className={styles.Chart}>
        <Column {...chartConfig} />
      </div>
    </div>
  );
};

export default DocumentDownloadChart;
