import { Array, Undefined } from "runtypes";
import { privateApi } from ".";
import { APIEndpoints } from "../../utils/constants";
import { JSONAPI } from "../../utils/jsonAPI";
import {
  AssociateGroupParams,
  DsyncGroup,
  DsyncGroupRT,
  GetDsyncGroupsMeta,
  GetDsyncGroupsMetaRT,
  SSOAdminPortalLinkType,
} from "../UserManagement/types";
import { Permission } from "./permissions";
import {
  AccessGroup,
  AccessGroupId,
  AccessGroupRT,
  CompanyId,
  GetAccessGroupUsersMeta,
  GetAccessGroupUsersMetaRT,
  GetAccessGroupsMeta,
  GetAccessGroupsMetaRT,
  ProductId,
  SimpleUser,
  SimpleUserRT,
  TotalCountMeta,
  TotalCountMetaRT,
  User,
  UserId,
  UserRT,
} from "./types";
import { mutationEndpointBuilder, queryEndpointBuilder } from "./utils";

interface GetUsersWithPermissionParams {
  permission: Permission;
  productIds: ProductId[];
  nameQuery?: string;
  limit?: number;
  offset?: number;
}

export interface GetUsersParams {
  companyId: CompanyId;
  inactive?: boolean;
  pending?: boolean;
  unverified?: boolean;
  includeAccessGroups?: boolean;
  name?: string;
  limit?: number;
  offset?: number;
}

const extendedApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    addUser: mutationEndpointBuilder<
      void,
      void,
      {
        invitedEmail: string;
        companyId?: CompanyId;
        accessGroupId: AccessGroupId;
      }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.userManagement.ADD_USER,
      body: (params) => params,
      invalidatesTags: ["TeamMember"],
    }),
    removeUser: mutationEndpointBuilder<void, void, string>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.userManagement.REMOVE_USER,
      body: (email) => ({ email }),
      invalidatesTags: ["TeamMember"],
    }),
    updateUserActive: mutationEndpointBuilder<
      void,
      void,
      { email: string; isActive: boolean }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.userManagement.UPDATE_ACTIVE_STATUS,
      body: (params) => params,
      invalidatesTags: ["TeamMember"],
    }),
    resetMFA: mutationEndpointBuilder<void, void, UserId>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.userManagement.RESET_MFA,
      body: (userId) => ({ user_id: userId }),
      invalidatesTags: ["TeamMember"],
    }),
    getUser: queryEndpointBuilder<void, SimpleUser, UserId>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: SimpleUserRT,
      url: (id) => APIEndpoints.userManagement.GET_USER(id),
    }),
    getUsersById: builder.query<SimpleUser[], UserId[]>({
      async queryFn(ids: UserId[], __, ___, fetchBq) {
        const userResponses: JSONAPI<void, SimpleUser>[] = await Promise.all(
          ids.map(async (id) => {
            const response = await fetchBq(
              APIEndpoints.userManagement.GET_USER(id),
            );
            return response.data as JSONAPI<void, SimpleUser>;
          }),
        );
        return { data: userResponses.map((r) => r.data) };
      },
    }),
    getUsersWithPermission: queryEndpointBuilder<
      TotalCountMeta,
      SimpleUser[],
      GetUsersWithPermissionParams
    >({
      builder,
      metaRuntype: TotalCountMetaRT,
      dataRuntype: Array(SimpleUserRT),
      url: (params) => {
        const urlSearchParams = new URLSearchParams();
        const { permission, productIds, nameQuery, limit, offset } = params;
        urlSearchParams.append("permission", permission.toString());

        productIds.forEach((productId) => {
          urlSearchParams.append("productIds", productId.toString());
        });
        if (nameQuery) {
          urlSearchParams.append("nameQuery", nameQuery);
        }
        if (limit) {
          urlSearchParams.append("limit", limit.toString());
        }
        if (offset) {
          urlSearchParams.append("offset", offset.toString());
        }
        return `${
          APIEndpoints.userManagement.GET_USERS_WITH_PERMISSION
        }?${urlSearchParams.toString()}`;
      },
    }),
    getUsers: queryEndpointBuilder<TotalCountMeta, User[], GetUsersParams>({
      builder,
      metaRuntype: TotalCountMetaRT,
      dataRuntype: Array(UserRT),
      url: (params) => {
        const urlSearchParams = new URLSearchParams();
        const {
          companyId,
          inactive,
          pending,
          unverified,
          includeAccessGroups,
          name,
          limit,
          offset,
        } = params;
        urlSearchParams.append("companyId", companyId.toString());
        if (inactive) {
          urlSearchParams.append("inactive", inactive.toString());
        }
        if (pending) {
          urlSearchParams.append("pending", pending.toString());
        }
        if (unverified) {
          urlSearchParams.append("unverified", unverified.toString());
        }
        if (name) {
          urlSearchParams.append("name", name);
        }
        if (offset) {
          urlSearchParams.append("offset", offset.toString());
        }
        if (limit) {
          urlSearchParams.append("limit", limit.toString());
        }
        if (includeAccessGroups) {
          urlSearchParams.append(
            "includeAccessGroups",
            includeAccessGroups.toString(),
          );
        }
        return `${
          APIEndpoints.userManagement.GET_USERS
        }?${urlSearchParams.toString()}`;
      },
      providesTags: ["TeamMember"],
    }),
    generateAdminPortalLink: builder.mutation<
      { url: string },
      { type: SSOAdminPortalLinkType }
    >({
      query: ({ type }) => ({
        url: APIEndpoints.userManagement.SSO_ADMIN_PORTAL,
        method: "POST",
        body: { type },
      }),
    }),
    getDsyncGroups: queryEndpointBuilder<
      GetDsyncGroupsMeta,
      DsyncGroup[],
      { companyId: CompanyId }
    >({
      builder,
      metaRuntype: GetDsyncGroupsMetaRT,
      dataRuntype: Array(DsyncGroupRT),
      url: (params) => {
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append("companyId", params.companyId.toString());
        return `${APIEndpoints.userManagement.GET_DSYNC_GROUPS}?${urlSearchParams.toString()}`;
      },
    }),
    createOrUpdateGroup: mutationEndpointBuilder<
      void,
      AccessGroup,
      AssociateGroupParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: AccessGroupRT,
      url: () => APIEndpoints.userManagement.CREATE_OR_UPDATE_ACCESS_GROUP,
      body: (params) => params,
      invalidatesTags: ["AccessGroup", "MeData"],
    }),
    getAccessGroups: queryEndpointBuilder<
      GetAccessGroupsMeta,
      AccessGroup[],
      { companyId?: CompanyId } | void
    >({
      builder,
      metaRuntype: GetAccessGroupsMetaRT,
      dataRuntype: Array(AccessGroupRT),
      url: (params) => {
        const urlSearchParams = new URLSearchParams();
        const { companyId } = params || {};
        if (companyId) {
          urlSearchParams.append("companyId", companyId.toString());
        }
        return `${APIEndpoints.userManagement.GET_ACCESS_GROUPS}?${urlSearchParams.toString()}`;
      },
      providesTags: ["AccessGroup"],
    }),
    deleteAccessGroup: mutationEndpointBuilder<
      void,
      void,
      { groupId: AccessGroupId; companyId: CompanyId }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.userManagement.DELETE_ACCESS_GROUP,
      body: (params) => params,
      invalidatesTags: ["AccessGroup"],
    }),
    getAccessGroupUsers: queryEndpointBuilder<
      GetAccessGroupUsersMeta,
      SimpleUser[],
      {
        groupId: AccessGroupId;
        offset?: number;
        limit?: number;
        nameQuery?: string;
      }
    >({
      builder,
      metaRuntype: GetAccessGroupUsersMetaRT,
      dataRuntype: Array(SimpleUserRT),
      url: (params) => {
        const urlSearchParams = new URLSearchParams();
        if (params.offset) {
          urlSearchParams.append("offset", params.offset.toString());
        }
        if (params.limit) {
          urlSearchParams.append("limit", params.limit.toString());
        }
        if (params.nameQuery) {
          urlSearchParams.append("nameQuery", params.nameQuery);
        }
        return `${APIEndpoints.userManagement.GET_ACCESS_GROUP_USERS(
          params.groupId,
        )}?${urlSearchParams.toString()}`;
      },
      providesTags: ["AccessGroupUser"],
    }),
    addAccessGroupUsers: mutationEndpointBuilder<
      void,
      void,
      { userIds: UserId[]; groupId: AccessGroupId; companyId: CompanyId }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: ({ groupId }) =>
        APIEndpoints.userManagement.ADD_ACCESS_GROUP_USERS(groupId),
      body: ({ userIds, companyId }) => ({ userIds, companyId }),
      invalidatesTags: ["AccessGroupUser", "TeamMember"],
    }),
    removeAccessGroupUsers: mutationEndpointBuilder<
      void,
      void,
      { userIds: UserId[]; groupId: AccessGroupId; companyId: CompanyId }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: ({ groupId }) =>
        APIEndpoints.userManagement.REMOVE_ACCESS_GROUP_USERS(groupId),
      body: ({ userIds, companyId }) => ({ userIds, companyId }),
      invalidatesTags: ["AccessGroupUser", "TeamMember"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddUserMutation,
  useGetUserQuery,
  useGetUsersByIdQuery,
  useCreateOrUpdateGroupMutation,
  useDeleteAccessGroupMutation,
  useUpdateUserActiveMutation,
  useRemoveUserMutation,
  useGetAccessGroupsQuery,
  useGetAccessGroupUsersQuery,
  useGetDsyncGroupsQuery,
  useLazyGetUsersQuery,
  useGetUsersQuery,
  useGetUsersWithPermissionQuery,
  useGenerateAdminPortalLinkMutation,
  useAddAccessGroupUsersMutation,
  useRemoveAccessGroupUsersMutation,
  useResetMFAMutation,
} = extendedApi;
