import {
  CloudDownloadOutlined,
  EditOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { Button, ConfigProvider, Tabs, TabsProps } from "antd";
import Color from "color";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AssuranceDocumentResponse,
  ProfileVersionResponse,
} from "../../../app_frontend/src/features/AssuranceProfile/autoGeneratedTypes";
import AssuranceProfileCard from "../AssuranceProfileCard";
import DownloadCompleteModal from "../BulkDownloadCompleteModal";
import BulkDownloadModal from "../BulkDownloadModal";
import CardHeader from "../CardHeader";
import DocumentCards from "../DocumentCards";
import styles from "./DocumentSection.module.scss";

interface DocumentsSectionProps {
  profileVersion: ProfileVersionResponse;
  customerAssuranceDocuments: AssuranceDocumentResponse[];
  requestInfoFormRef: React.RefObject<HTMLDivElement>;
  onDownloadPublicDocument: (document: AssuranceDocumentResponse) => void;
  onBulkDownloadPublicDocuments: (documents: string[]) => Promise<void>;
  showEditButton?: boolean;
}
const DocumentsSection: React.FC<DocumentsSectionProps> = ({
  profileVersion,
  customerAssuranceDocuments,
  requestInfoFormRef,
  onDownloadPublicDocument,
  onBulkDownloadPublicDocuments,
  showEditButton,
}) => {
  const [isBulkDownloadModalVisible, setIsBulkDownloadModalVisible] =
    useState(false);

  const [selectedDocumentIdsLength, setSelectedDocumentIdsLength] = useState(0);
  const [isBulkDownloadCompleted, setIsBulkDownloadComplete] = useState(false);
  const [isBulkDownloadError, setIsBulkDownloadError] = useState(false);

  const accentColor =
    profileVersion.relationships.styleSettings.attributes.primaryColor;

  const bulkDownloadPublicDocuments = async (selectedDocumentIds: string[]) => {
    setSelectedDocumentIdsLength(selectedDocumentIds.length);
    try {
      await onBulkDownloadPublicDocuments(selectedDocumentIds);
      setIsBulkDownloadModalVisible(false);
    } catch (_error) {
      setIsBulkDownloadError(true);
    } finally {
      setIsBulkDownloadComplete(true);
    }
  };

  const RequestAccessButton = () => (
    <div className={styles.RequestAccessButton}>
      <Button
        type="primary"
        onClick={() => {
          if (requestInfoFormRef.current) {
            requestInfoFormRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }}
      >
        Request access to private documents
      </Button>
    </div>
  );
  const publicDocuments = customerAssuranceDocuments.filter(
    (document) => document.attributes.isPublic,
  );

  const privateDocuments = customerAssuranceDocuments.filter(
    (document) => !document.attributes.isPublic,
  );

  const tabItems: TabsProps["items"] = [];

  if (publicDocuments.length > 0 && privateDocuments.length > 0) {
    tabItems.push({
      key: "All",
      label: <span className="TileText">All</span>,
      children: (
        <>
          <RequestAccessButton />
          <DocumentCards
            customerAssuranceDocuments={customerAssuranceDocuments}
            onDownloadPublicDocument={onDownloadPublicDocument}
          />
        </>
      ),
    });
  }

  if (publicDocuments.length > 0) {
    tabItems.push({
      key: "Public",
      label: <span className="TileText">Public</span>,
      children: (
        <DocumentCards
          customerAssuranceDocuments={publicDocuments}
          onDownloadPublicDocument={onDownloadPublicDocument}
        />
      ),
    });
  }

  if (privateDocuments.length > 0) {
    tabItems.push({
      key: "Private",
      label: <span className="TileText">Private</span>,
      children: (
        <>
          <RequestAccessButton />
          <DocumentCards
            customerAssuranceDocuments={privateDocuments}
            onDownloadPublicDocument={onDownloadPublicDocument}
          />
        </>
      ),
    });
  }
  const EditButton: React.FC = () => {
    const navigate = useNavigate();
    return (
      <Button
        onClick={() => {
          navigate(
            `/assurance-profiles/${profileVersion.attributes.profileId}/versions/${profileVersion.id}/documents`,
          );
        }}
        icon={<EditOutlined />}
      />
    );
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: accentColor,
            colorPrimaryHover: Color(accentColor).alpha(0.75).string(),
          },
          Tabs: {
            colorPrimary: accentColor,
            colorPrimaryHover: Color(accentColor).alpha(0.75).string(),
          },
          Checkbox: {
            colorPrimary: accentColor,
            colorPrimaryHover: Color(accentColor).alpha(0.75).string(),
          },
        },
      }}
    >
      {" "}
      <AssuranceProfileCard className={styles.Card}>
        <div className={styles.DocumentsSectionHeader}>
          <CardHeader
            icon={<FilePdfOutlined />}
            title="Documents"
            titleLevel={2}
            editButton={showEditButton ? <EditButton /> : undefined}
          />
          {publicDocuments.length > 0 && (
            <Button
              type="primary"
              icon={<CloudDownloadOutlined className={styles.DownloadIcon} />}
              onClick={() => {
                setIsBulkDownloadModalVisible(true);
              }}
            >
              Bulk download
            </Button>
          )}
        </div>
        <Tabs items={tabItems} size="middle" />
      </AssuranceProfileCard>
      {isBulkDownloadModalVisible && (
        <BulkDownloadModal
          onClose={() => {
            setIsBulkDownloadModalVisible(false);
          }}
          customerAssuranceDocuments={publicDocuments}
          onBulkDownloadPublicDocuments={bulkDownloadPublicDocuments}
        />
      )}
      {isBulkDownloadCompleted && (
        <DownloadCompleteModal
          downloadedCount={selectedDocumentIdsLength}
          onClose={() => {
            setIsBulkDownloadComplete(false);
            setIsBulkDownloadError(false);
          }}
          onError={isBulkDownloadError}
        />
      )}
    </ConfigProvider>
  );
};
export default DocumentsSection;
