import { Select } from "antd";
import clsx from "clsx";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CompanyId, Product, ProductId } from "../../features/API/types";
import { setActiveProduct } from "../../features/Layout";
import { useActiveProduct, useGetCompanyProducts } from "../../utils/hooks";

interface ProductOption {
  value: ProductId;
  label: string;
}
interface SingleProductSelectorProps {
  companyId?: CompanyId | undefined;
  size?: "small" | "middle" | "large";
  className?: string;
  onChange?: (product: Product | undefined) => void;
  options?: Product[];
}

export const SingleProductSelector = ({
  companyId,
  size,
  className,
  onChange,
}: SingleProductSelectorProps): JSX.Element => {
  const dispatch = useDispatch();
  const activeProduct = useActiveProduct();
  const products = useGetCompanyProducts(companyId);

  useEffect(() => {
    if (!activeProduct) {
      dispatch(setActiveProduct(products[0]));
    }
  }, [products, dispatch, activeProduct]);
  const productsOptions = products.map((p) => ({
    label: p.attributes.productName,
    value: p.id,
  }));

  return (
    <Select<ProductId, ProductOption>
      placeholder="Select product"
      options={productsOptions}
      showSearch
      onSelect={(value: ProductId) => {
        if (onChange) {
          const product = products.find((p) => p.id === value);
          onChange(product);
        }
      }}
      className={clsx(className)}
      size={size}
      value={activeProduct?.id}
      aria-label="Product selector"
    >
      {productsOptions.map(({ label, value }) => (
        <Select.Option key={value} value={value}>
          {label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SingleProductSelector;
