import { Column } from "@ant-design/plots";
import { Result, Skeleton } from "antd";
import { useGetAssuranceProfilePrivateDataRoomAnalyticsQuery } from "../../../features/API/AnalyticsDashboard";
import { filterData } from "../utils";
import { AssuranceProfileChartProps } from "./AssuranceProfileAnalyticsContainer";

const PrivateDataRoomChart = ({
  selectedProfileIds,
  activeFilter,
  theme,
  height,
  width,
}: AssuranceProfileChartProps): JSX.Element => {
  const {
    data: dataRoomStats,
    isLoading,
    error,
  } = useGetAssuranceProfilePrivateDataRoomAnalyticsQuery({
    profileIds: selectedProfileIds,
  });

  if (isLoading) {
    return <Skeleton active />;
  }

  if (error) {
    return (
      <Result
        status="error"
        title="Failed to Load Data Room Statistics"
        subTitle="An unexpected error occurred while fetching data room analytics"
      />
    );
  }

  if (
    !dataRoomStats?.data ||
    dataRoomStats.data.length === 0 ||
    dataRoomStats.data.every((stat) => stat.totalLinks === 0)
  ) {
    return (
      <Result
        status="info"
        title="No Data Room Statistics"
        subTitle="There are no data room statistics available for the selected profiles"
      />
    );
  }

  const config = {
    theme,
    data: filterData(dataRoomStats.data, activeFilter),
    xField: "date",
    yField: "totalLinks",
    height,
    width,
    tooltip: {
      items: [{ name: "Private Data Rooms", channel: "y" }],
    },
    axis: {
      y: { title: "Data Rooms Created" },
      x: { title: "Month" },
    },
    style: {
      maxWidth: 20,
    },
  };

  return <Column {...config} />;
};

export default PrivateDataRoomChart;
