import { RcFile } from "antd/lib/upload";
import { Array, String, Undefined } from "runtypes";
import {
  CustomerAssuranceNDAId,
  DocusignTemplate,
  DocusignTemplateRT,
  Meta,
  MetaRT,
  PrivateDataRoomLinkId,
} from "shared_frontend/src/types/assuranceProfile";
import { privateApi } from "..";
import { APIEndpoints } from "../../../utils/constants";
import { JSONAPI } from "../../../utils/jsonAPI";
import {
  AddCompanyPublicKeyRequest,
  AssuranceDocumentResponse,
  CertificationResponse,
  CertificationSelectionResponse,
  ChangeProfileVersionTypeParams,
  CheckNDAInPlaceForEmailParams,
  CreateProfileSwitcherParams,
  CreateProfileSwitcherResponse,
  DeleteProfileSwitcherParams,
  DocumentGroupResponse,
  DocusignNdaResponse,
  FileBasedNdaResponse,
  GetCheckProfileNdaAllowlistResponse,
  GetProfileNdaAllowlistParams,
  GetSubscribersMeta,
  GetSubscribersParams,
  IroncladNdaResponse,
  LogoResponse,
  PartialProfileVersionResponse,
  ProfileResponse,
  ProfileSwitcherResponse,
  ProfileVersionResponse,
  PublicKeyIssuerId,
  RequestInformationSettingResponse,
  SetupIroncladParams,
  StyleSettingResponse,
  SubprocessorResponse,
  SubscriberResponse,
  TileItemResponse,
  TileResponse,
  UpdateOrderParams,
  UpdateProfileSwitcherParams,
  UpdateProfileVersionOverviewTitleParams,
  UpdateResponse,
  UpdateSwitcherForProfileVersionParams,
} from "../../AssuranceProfile/autoGeneratedTypes";
import { RefreshPrivateDataRoomLinkParams } from "../../AssuranceProfile/generatedTypes/accessDataRoomTypes";
import {
  CreateCertificationParams,
  DeleteCertificationParams,
  GetCertificationsParams,
  PostProcessCertificationsParams,
  UpdateCertificationParams,
  UpdateCertificationSelectionRankParams,
  UpdateCertificationSelectionsParams,
} from "../../AssuranceProfile/generatedTypes/certificationTypes";
import {
  CreateDocumentGroupParams,
  DeleteDocumentGroupParams,
  UpdateDocumentGroupParams,
} from "../../AssuranceProfile/generatedTypes/documentGroupTypes";
import {
  CreateAssuranceDocumentParams,
  DeleteAssuranceDocumentParams,
  UpdateAssuranceDocumentOrderParams,
  UpdateAssuranceDocumentParams,
} from "../../AssuranceProfile/generatedTypes/documentTypes";
import {
  CreateLogoParams,
  UpdateLogoParams,
} from "../../AssuranceProfile/generatedTypes/logoTypes";
import {
  DisconnectDocusignParams,
  UpdateDocusignTemplateIdParams,
  UpsertNDADocumentParams,
} from "../../AssuranceProfile/generatedTypes/ndaTypes";
import {
  ApprovePrivateDataRoomLinkParams,
  GeneratePrivateDataRoomParams,
  PrivateDataRoomEventResponse,
  PrivateDataRoomLinkEventsParams,
  PrivateDataroomLinkResponse,
} from "../../AssuranceProfile/generatedTypes/privateDataRoomTypes";
import {
  CheckExistingSalesforceTokens,
  CreateProfileParams,
  GetAssuranceProfileUsageStatsParams,
  GetPrivateDataRoomLinksParams,
  GetProfileParams,
  GetProfilesParams,
  GetSalesforceAuthUrl,
  GetSalesforceAuthUrlParams,
  UpdateCompanySalesforceIntegrationParams,
  UpdateProfileParams,
} from "../../AssuranceProfile/generatedTypes/profileTypes";
import {
  CreateDraftProfileVersionParams,
  DeleteDraftProfileVersionParams,
  PublicProfileVersionData,
  UpdateProfileVersionHtmlHeadParams,
  UpdateProfileVersionInUseParams,
  UpdateProfileVersionOverviewParams,
  UpdateProfileVersionTitleParams,
} from "../../AssuranceProfile/generatedTypes/profileVersionTypes";
import {
  ApproveInformationRequestParams,
  EnvelopeResponse,
  GetInformationRequestsParams,
  InformationRequestResponse,
  RejectInformationRequestParams,
  RequestInformationUpdateCompanyAgreementsParams,
  RequestInformationUpdateSettingsParams,
  UpdateRequestInfoSubtitleParams,
  UpdateRequestInfoTitleParams,
} from "../../AssuranceProfile/generatedTypes/requestInformationTypes";
import { UpdateStyleSettingsParams } from "../../AssuranceProfile/generatedTypes/styleSettingsTypes";
import {
  CreateSubprocessorParams,
  UpdateSubprocessorParams,
  UpdateSubprocessorRankParams,
} from "../../AssuranceProfile/generatedTypes/subprocessorTypes";
import {
  CreateTileItemParams,
  CreateTileParams,
  UpdateTileItemParams,
  UpdateTileItemRankParams,
  UpdateTileParams,
  UpdateTileRankParams,
} from "../../AssuranceProfile/generatedTypes/tileTypes";
import {
  CreateAssuranceProfileUpdateParams,
  DeleteAssuranceProfileUpdateParams,
  UpdateAssuranceProfileUpdateParams,
} from "../../AssuranceProfile/generatedTypes/updateTypes";
import {
  DataRoomEventsFilters,
  DataRoomLinkId,
  MagicLinkAuthToken,
  MagicLinkAuthTokenRT,
  MagicLinkId,
  ProfileUsageStats,
} from "../../AssuranceProfile/types";
import { CompanyId } from "../types";
import { mutationEndpointBuilder, queryEndpointBuilder } from "../utils";
import {
  constructAssuranceProfileUsageStatsQueryParams,
  constructPrivateDataRoomEventsQueryParams,
  constructPrivateDataRoomLinksQueryParams,
} from "./utils";

type JwtTokenParams = {
  jwtToken: string;
  privateDataRoomLinkId: PrivateDataRoomLinkId;
};
type SendMagicLinkEmailParams = {
  privateDataRoomLinkId: PrivateDataRoomLinkId;
  recipientEmail: string;
};

const extendedApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    createProfile: mutationEndpointBuilder<
      void,
      ProfileResponse,
      CreateProfileParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => APIEndpoints.customerAssurance.profiles.CREATE_PROFILE,
      body: (data) => data,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["Profile"],
    }),
    getProfile: queryEndpointBuilder<void, ProfileResponse, GetProfileParams>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: ({
        profileId,
        includeActiveVersion,
        includeDocuments,
        includeNda,
      }) => {
        const params = new URLSearchParams();
        params.append("profileId", profileId);
        params.append(
          "includeActiveVersion",
          includeActiveVersion?.toString() || "false",
        );
        params.append(
          "includeDocuments",
          includeDocuments?.toString() || "false",
        );
        params.append("includeNda", includeNda?.toString() || "false");
        return `${
          APIEndpoints.customerAssurance.profiles.GET_PROFILE
        }?${params.toString()}`;
      },
      extraOptions: {
        checkRunTypes: false,
      },
      providesTags: ["Profile"],
    }),
    getProfiles: queryEndpointBuilder<
      void,
      ProfileResponse[],
      GetProfilesParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: ({
        companyId,
        includeActiveVersion,
        includeDocuments,
        includeNda,
      }) => {
        const params = new URLSearchParams();
        params.append("companyId", companyId);
        params.append(
          "includeActiveVersion",
          includeActiveVersion?.toString() || "false",
        );
        params.append(
          "includeDocuments",
          includeDocuments?.toString() || "false",
        );
        params.append("includeNda", includeNda?.toString() || "false");
        return `${
          APIEndpoints.customerAssurance.profiles.GET_PROFILES
        }?${params.toString()}`;
      },
      extraOptions: {
        checkRunTypes: false,
      },
      providesTags: ["Profile"],
    }),
    getSubscribers: queryEndpointBuilder<
      GetSubscribersMeta,
      SubscriberResponse[],
      GetSubscribersParams
    >({
      builder,
      metaRuntype: undefined,
      dataRuntype: undefined,
      url: ({ profileId, limit, offset }) => {
        const params = new URLSearchParams();
        params.append("profileId", profileId);
        if (limit !== undefined) {
          params.append("limit", limit.toString());
        }
        if (offset !== undefined) {
          params.append("offset", offset.toString());
        }
        return `${
          APIEndpoints.customerAssurance.profiles.GET_SUBSCRIBERS
        }?${params.toString()}`;
      },
    }),
    updateProfile: mutationEndpointBuilder<
      void,
      ProfileResponse,
      UpdateProfileParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => APIEndpoints.customerAssurance.profiles.UPDATE_PROFILE,
      body: (data) => data,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["Profile"],
    }),
    deleteProfile: mutationEndpointBuilder<void, void, string>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.customerAssurance.profiles.DELETE_PROFILE,
      body: (profileId) => ({ profileId }),
      invalidatesTags: ["Profile"],
    }),
    getProfileUsageStats: queryEndpointBuilder<
      void,
      ProfileUsageStats,
      GetAssuranceProfileUsageStatsParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: (params) => {
        return `${
          APIEndpoints.customerAssurance.profiles.GET_USAGE_STATS
        }?${constructAssuranceProfileUsageStatsQueryParams(params)}`;
      },
      extraOptions: {
        checkRunTypes: false,
      },
    }),
    getProfileVersion: queryEndpointBuilder<
      void,
      ProfileVersionResponse,
      string
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: (id) => {
        const params = new URLSearchParams();
        params.append("profileVersionId", id);
        return `${
          APIEndpoints.customerAssurance.profileVersions.GET_PROFILE_VERSION
        }?${params.toString()}`;
      },
      extraOptions: {
        checkRunTypes: false,
      },
      providesTags: ["ProfileVersion"],
    }),
    getPublicProfileVersion: queryEndpointBuilder<
      void,
      PublicProfileVersionData,
      string
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: (subdomain) => {
        const params = new URLSearchParams();
        params.append("subdomain", subdomain);
        return `${
          APIEndpoints.customerAssurance.profileVersions
            .GET_PUBLIC_PROFILE_VERSION
        }?${params.toString()}`;
      },
      extraOptions: {
        checkRunTypes: false,
      },
      providesTags: ["ProfileVersion"],
    }),
    getProfileVersions: queryEndpointBuilder<
      void,
      ProfileVersionResponse[] | PartialProfileVersionResponse[],
      { id?: string; excludeRelationships: boolean }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: ({ id, excludeRelationships }) => {
        const params = new URLSearchParams();
        params.append("profileId", id ?? "");
        params.append("excludeRelationships", excludeRelationships.toString());
        return `${
          APIEndpoints.customerAssurance.profileVersions.GET_PROFILE_VERSIONS
        }?${params.toString()}`;
      },
      extraOptions: {
        checkRunTypes: false,
      },
      providesTags: ["ProfileVersion"],
    }),
    createDraftProfileVersion: mutationEndpointBuilder<
      void,
      ProfileVersionResponse,
      CreateDraftProfileVersionParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () =>
        APIEndpoints.customerAssurance.profileVersions
          .CREATE_DRAFT_PROFILE_VERSION,
      body: (data) => data,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion", "Certification"],
    }),
    deleteDraftProfileVersion: mutationEndpointBuilder<
      void,
      void,
      DeleteDraftProfileVersionParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () =>
        APIEndpoints.customerAssurance.profileVersions
          .DELETE_DRAFT_PROFILE_VERSION,
      body: (data) => data,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion"],
    }),
    updateOverview: mutationEndpointBuilder<
      void,
      ProfileVersionResponse,
      UpdateProfileVersionOverviewParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => APIEndpoints.customerAssurance.profileVersions.UPDATE_OVERVIEW,
      body: (data) => data,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion"],
    }),
    updateOverviewTitle: mutationEndpointBuilder<
      void,
      ProfileVersionResponse,
      UpdateProfileVersionOverviewTitleParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () =>
        APIEndpoints.customerAssurance.profileVersions.UPDATE_OVERVIEW_TITLE,
      body: (data) => data,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion"],
    }),
    updateInUse: mutationEndpointBuilder<
      void,
      ProfileVersionResponse,
      UpdateProfileVersionInUseParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => APIEndpoints.customerAssurance.profileVersions.UPDATE_IN_USE,
      body: (params) => params,
      extraOptions: {
        checkRunTypes: false,
      },
      //invalidates Profile because activeVersion may change
      invalidatesTags: ["ProfileVersion", "Profile"],
    }),
    finalizeDraftProfileVersion: mutationEndpointBuilder<
      void,
      ProfileVersionResponse,
      string
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () =>
        APIEndpoints.customerAssurance.profileVersions.FINALIZE_DRAFT_VERSION,
      body: (profileVersionId) => ({
        profileVersionId,
      }),
      extraOptions: {
        checkRunTypes: false,
      },
      // invalidates Profile because activeVersion may change
      invalidatesTags: ["ProfileVersion", "Profile"],
    }),
    changeProfileVersionType: mutationEndpointBuilder<
      void,
      void,
      ChangeProfileVersionTypeParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () =>
        APIEndpoints.customerAssurance.profileVersions
          .CHANGE_PROFILE_VERSION_TYPE,
      body: (params) => params,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion"],
    }),
    updateCertificationSelections: mutationEndpointBuilder<
      void,
      void,
      UpdateCertificationSelectionsParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () =>
        APIEndpoints.customerAssurance.certifications
          .UPDATE_CERTIFICATION_SELECTIONS,
      body: (data) => data,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion"],
    }),
    updateCertificationSelectionRank: mutationEndpointBuilder<
      void,
      CertificationSelectionResponse,
      UpdateCertificationSelectionRankParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () =>
        APIEndpoints.customerAssurance.certifications
          .UPDATE_CERTIFICATION_SELECTION_RANK,
      body: (data) => data,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion"],
    }),
    getCertifications: queryEndpointBuilder<
      void,
      CertificationResponse[],
      GetCertificationsParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: (args) => {
        const params = new URLSearchParams();
        if (args?.companyId !== undefined && args?.companyId !== null) {
          params.append("companyId", args.companyId);
        }

        return `${
          APIEndpoints.customerAssurance.certifications.GET_CERTIFICATIONS
        }?${params.toString()}`;
      },
      extraOptions: {
        checkRunTypes: false,
      },
      providesTags: ["Certification"],
    }),
    createCertification: mutationEndpointBuilder<
      void,
      CertificationResponse,
      CreateCertificationParams & { file: File }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () =>
        APIEndpoints.customerAssurance.certifications.CREATE_CERTIFICATION,
      body: (data) => {
        const form = new FormData();
        form.append("profileVersionId", data.profileVersionId);
        form.append("name", data.name);
        form.append("file", data.file);
        if (data.companyId) {
          form.append("companyId", data.companyId);
        }

        return form;
      },
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["Certification", "ProfileVersion"],
    }),
    postProcessCertificationAfterSignedUpload: mutationEndpointBuilder<
      void,
      CertificationResponse,
      PostProcessCertificationsParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () =>
        APIEndpoints.customerAssurance.certifications
          .POST_PROCESS_AFTER_CERTIFICATION_UPLOAD,
      body: (params) => params,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["Certification", "ProfileVersion"],
    }),
    updateCertification: mutationEndpointBuilder<
      void,
      CertificationResponse,
      UpdateCertificationParams & { file?: RcFile }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () =>
        APIEndpoints.customerAssurance.certifications.UPDATE_CERTIFICATION,
      body: (data) => {
        const form = new FormData();
        form.append("certificationId", data.certificationId);
        form.append("certificationName", data.certificationName);
        if (data.file) {
          form.append("file", data.file);
        }
        return form;
      },
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion", "Certification"],
    }),
    deleteCertification: mutationEndpointBuilder<
      void,
      void,
      DeleteCertificationParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () =>
        APIEndpoints.customerAssurance.certifications.DELETE_CERTIFICATION,
      body: (data) => data,
      invalidatesTags: ["ProfileVersion", "Certification"],
    }),
    getCustomerAssuranceDocumentGroups: queryEndpointBuilder<
      void,
      DocumentGroupResponse[],
      string
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: (id) => {
        const params = new URLSearchParams();
        params.append("profileVersionId", id);
        return `${
          APIEndpoints.customerAssurance.documentGroups.GET_DOCUMENT_GROUPS
        }?${params.toString()}`;
      },
      extraOptions: {
        checkRunTypes: false,
      },
      providesTags: ["CustomerAssuranceDocumentGroups"],
    }),
    getCustomerAssuranceDocuments: queryEndpointBuilder<
      void,
      AssuranceDocumentResponse[],
      string
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: (id) => {
        const params = new URLSearchParams();
        params.append("profileVersionId", id);
        return `${
          APIEndpoints.customerAssurance.documents.GET_DOCUMENTS
        }?${params.toString()}`;
      },
      extraOptions: {
        checkRunTypes: false,
      },
      providesTags: ["CustomerAssuranceDocument"],
    }),
    getCustomerAssurancePrivateDocuments: queryEndpointBuilder<
      void,
      AssuranceDocumentResponse[],
      DataRoomLinkId
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: (id) => {
        const params = new URLSearchParams();
        params.append("linkId", id);
        return `${
          APIEndpoints.customerAssurance.documents.GET_PRIVATE_DOCUMENTS
        }?${params.toString()}`;
      },
      extraOptions: {
        checkRunTypes: false,
      },
    }),
    verifyPrivateDataRoomLink: queryEndpointBuilder<void, void, string>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: (id) =>
        APIEndpoints.customerAssurance.privateDataRooms.VERIFY_PRIVATE_DATA_ROOM(
          id,
        ),
    }),
    generatePrivateDataRoomLink: mutationEndpointBuilder<
      void,
      PrivateDataroomLinkResponse,
      GeneratePrivateDataRoomParams & { profileVersionId: string }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: ({ profileVersionId }) =>
        APIEndpoints.customerAssurance.privateDataRooms.GENERATE_PRIVATE_DATA_ROOM_LINK(
          profileVersionId,
        ),
      body: (params) => params,
      invalidatesTags: ["DataRoomLinks"],
    }),
    sendMagicLinkEmail: mutationEndpointBuilder<
      void,
      void,
      SendMagicLinkEmailParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () =>
        APIEndpoints.customerAssurance.magicLinks.SEND_MAGIC_LINK_EMAIL,
      body: (params) => params,
    }),
    authenticateMagicLink: mutationEndpointBuilder<
      void,
      MagicLinkAuthToken,
      { magicLinkId: MagicLinkId }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: MagicLinkAuthTokenRT,
      url: () =>
        APIEndpoints.customerAssurance.magicLinks.AUTHENTICATE_MAGIC_LINK,
      body: (params) => params,
    }),
    createCustomerAssuranceDocumentGroup: mutationEndpointBuilder<
      void,
      DocumentGroupResponse,
      CreateDocumentGroupParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () =>
        APIEndpoints.customerAssurance.documentGroups.CREATE_DOCUMENT_GROUP,
      body: (params) => {
        const form = new FormData();
        form.append("profileVersionId", params.profileVersionId);
        form.append("title", params.title);
        if (params.description) {
          form.append("description", params.description);
        }
        return form;
      },
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["CustomerAssuranceDocumentGroups"],
    }),
    updateCustomerAssuranceDocumentGroup: mutationEndpointBuilder<
      void,
      DocumentGroupResponse,
      UpdateDocumentGroupParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () =>
        APIEndpoints.customerAssurance.documentGroups.UPDATE_DOCUMENT_GROUP,
      body: (params) => params,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: [
        "CustomerAssuranceDocumentGroups",
        "CustomerAssuranceDocument",
      ],
    }),
    deleteCustomerAssuranceDocumentGroup: mutationEndpointBuilder<
      void,
      DeleteDocumentGroupParams,
      string
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () =>
        APIEndpoints.customerAssurance.documentGroups.DELETE_DOCUMENT_GROUP,
      body: (documentGroupId) => ({ documentGroupId }),
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: [
        "CustomerAssuranceDocumentGroups",
        "CustomerAssuranceDocument",
      ],
    }),

    createCustomerAssuranceDocument: mutationEndpointBuilder<
      void,
      AssuranceDocumentResponse,
      CreateAssuranceDocumentParams & { file: File }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => APIEndpoints.customerAssurance.documents.CREATE_DOCUMENT,
      body: (params) => {
        const form = new FormData();
        form.append("profileVersionId", params.profileVersionId);
        form.append("title", params.title);
        form.append("description", params.description);
        form.append("isPublic", params.isPublic.toString());
        form.append("file", params.file);
        if (
          params.isThirdPartyAttested !== undefined &&
          params.isThirdPartyAttested !== null
        ) {
          form.append(
            "isThirdPartyAttested",
            params.isThirdPartyAttested.toString(),
          );
        }
        if (params.groupId) {
          form.append("groupId", params.groupId);
        }
        if (params.reviewedAt) {
          form.append("reviewedAt", params.reviewedAt);
        }
        if (params.reviewCycle) {
          form.append("reviewCycle", params.reviewCycle.toString());
        }
        if (params.reviewedById) {
          form.append("reviewedById", params.reviewedById.toString());
        }
        return form;
      },
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["CustomerAssuranceDocument", "ProfileVersion"],
    }),
    deleteCustomerAssuranceDocument: mutationEndpointBuilder<
      void,
      void,
      DeleteAssuranceDocumentParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.customerAssurance.documents.DELETE_DOCUMENT,
      body: (documentId) => documentId,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["CustomerAssuranceDocument"],
    }),
    updateCustomerAssuranceDocument: mutationEndpointBuilder<
      void,
      AssuranceDocumentResponse,
      UpdateAssuranceDocumentParams & { file?: RcFile }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => APIEndpoints.customerAssurance.documents.UPDATE_DOCUMENT,
      body: (params) => {
        const form = new FormData();
        form.append("documentId", params.documentId);
        form.append("title", params.title);
        form.append("description", params.description);
        form.append("isPublic", params.isPublic.toString());
        if (
          params.isThirdPartyAttested !== undefined &&
          params.isThirdPartyAttested !== null
        ) {
          form.append(
            "isThirdPartyAttested",
            params.isThirdPartyAttested.toString(),
          );
        }
        if (params.file !== undefined) {
          form.append("file", params.file);
        }
        if (params.groupId) {
          form.append("groupId", params.groupId);
        }
        if (params.reviewedAt) {
          form.append("reviewedAt", params.reviewedAt);
        }
        if (params.reviewCycle) {
          form.append("reviewCycle", params.reviewCycle.toString());
        }
        if (params.reviewedById) {
          form.append("reviewedById", params.reviewedById.toString());
        }
        return form;
      },
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["CustomerAssuranceDocument", "ProfileVersion"],
    }),
    createAssuranceProfileUpdate: mutationEndpointBuilder<
      void,
      UpdateResponse,
      CreateAssuranceProfileUpdateParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () =>
        APIEndpoints.customerAssurance.assurance_profile_updates
          .CREATE_ASSURANCE_PROFILE_UPDATE,
      body: (data) => data,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion"],
    }),
    updateAssuranceProfileUpdate: mutationEndpointBuilder<
      void,
      UpdateResponse,
      UpdateAssuranceProfileUpdateParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () =>
        APIEndpoints.customerAssurance.assurance_profile_updates
          .UPDATE_ASSURANCE_PROFILE_UPDATE,
      body: (data) => data,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion"],
    }),
    deleteAssuranceProfileUpdate: mutationEndpointBuilder<
      void,
      void,
      DeleteAssuranceProfileUpdateParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () =>
        APIEndpoints.customerAssurance.assurance_profile_updates
          .DELETE_ASSURANCE_PROFILE_UPDATE,
      body: (updateId) => ({ updateId }),
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion"],
    }),
    createSubprocessor: mutationEndpointBuilder<
      void,
      SubprocessorResponse,
      CreateSubprocessorParams & { file?: RcFile }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () =>
        APIEndpoints.customerAssurance.subprocessors.CREATE_SUBPROCESSOR,
      body: (params) => {
        const form = new FormData();
        form.append("profileVersionId", params.profileVersionId);
        form.append("description", params.description);
        if (params.header) {
          form.append("header", params.header);
        }
        if (params.file) {
          form.append("file", params.file);
        }
        return form;
      },
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion"],
    }),
    updateSubprocessor: mutationEndpointBuilder<
      void,
      SubprocessorResponse,
      UpdateSubprocessorParams & { file?: RcFile }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () =>
        APIEndpoints.customerAssurance.subprocessors.UPDATE_SUBPROCESSOR,
      body: (params) => {
        const form = new FormData();
        form.append("subprocessorId", params.subprocessorId);
        form.append("description", params.description);
        if (params.header) {
          form.append("header", params.header);
        }
        if (params.deleteFile) {
          form.append("deleteFile", "true");
        } else if (params.file) {
          form.append("file", params.file);
        }
        return form;
      },
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion"],
    }),
    updateSubprocessorRank: mutationEndpointBuilder<
      void,
      SubprocessorResponse,
      UpdateSubprocessorRankParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () =>
        APIEndpoints.customerAssurance.subprocessors.UPDATE_SUBPROCESSOR_RANK,
      body: (data) => data,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion"],
    }),
    deleteSubprocessor: mutationEndpointBuilder<void, void, string>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () =>
        APIEndpoints.customerAssurance.subprocessors.DELETE_SUBPROCESSOR,
      body: (subprocessorId) => ({ subprocessorId }),
      invalidatesTags: ["ProfileVersion"],
    }),
    createTile: mutationEndpointBuilder<void, TileResponse, CreateTileParams>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => APIEndpoints.customerAssurance.tiles.CREATE_TILE,
      body: (data) => data,
      invalidatesTags: ["ProfileVersion"],
      extraOptions: {
        checkRunTypes: false,
      },
    }),
    updateTile: mutationEndpointBuilder<void, TileResponse, UpdateTileParams>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => APIEndpoints.customerAssurance.tiles.UPDATE_TILE,
      body: (data) => data,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion"],
    }),
    updateTileRank: mutationEndpointBuilder<
      void,
      TileResponse,
      UpdateTileRankParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => APIEndpoints.customerAssurance.tiles.UPDATE_TILE_RANK,
      body: (data) => data,
      invalidatesTags: ["ProfileVersion"],
      extraOptions: {
        checkRunTypes: false,
      },
    }),
    deleteTile: mutationEndpointBuilder<void, void, string>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.customerAssurance.tiles.DELETE_TILE,
      body: (tileId) => ({ tileId }),
      invalidatesTags: ["ProfileVersion"],
    }),
    getInformationRequests: queryEndpointBuilder<
      Meta,
      InformationRequestResponse[],
      GetInformationRequestsParams
    >({
      builder,
      metaRuntype: MetaRT,
      dataRuntype: undefined,
      url: (params) => {
        const queryParams = new URLSearchParams();
        params.profileIds?.forEach((profileId) =>
          queryParams.append("profileIds", profileId),
        );
        if (params.companyId !== undefined && params.companyId !== null) {
          queryParams.append("companyId", params.companyId.toString());
        }
        if (params.filter !== undefined && params.filter !== null) {
          queryParams.append("filter", params.filter);
        }
        if (params.limit !== undefined && params.limit !== null) {
          queryParams.append("limit", params.limit.toString());
        }
        if (params.offset !== undefined && params.offset) {
          queryParams.append("offset", params.offset.toString());
        }

        return `${
          APIEndpoints.customerAssurance.requestInformation
            .GET_INFORMATION_REQUESTS
        }?${queryParams.toString()}`;
      },
      extraOptions: {
        checkRunTypes: false,
      },
      providesTags: ["InformationRequest"],
    }),
    approveInformationRequest: mutationEndpointBuilder<
      void,
      EnvelopeResponse,
      ApproveInformationRequestParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () =>
        APIEndpoints.customerAssurance.requestInformation
          .APPROVE_INFORMATION_REQUEST,
      body: (data) => data,
      invalidatesTags: ["InformationRequest"],
      extraOptions: {
        checkRunTypes: false,
      },
    }),
    rejectInformationRequest: mutationEndpointBuilder<
      void,
      void,
      RejectInformationRequestParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () =>
        APIEndpoints.customerAssurance.requestInformation
          .REJECT_INFORMATION_REQUEST,
      body: (data) => data,
      invalidatesTags: ["InformationRequest"],
    }),
    updateRequestInformationSettings: mutationEndpointBuilder<
      void,
      RequestInformationSettingResponse,
      RequestInformationUpdateSettingsParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () =>
        APIEndpoints.customerAssurance.requestInformation.UPDATE_SETTINGS,
      body: (data) => data,
      extraOptions: { checkRunTypes: false },
      invalidatesTags: ["ProfileVersion"],
    }),
    updateRequestInformationCompanyAgreements: mutationEndpointBuilder<
      void,
      RequestInformationSettingResponse,
      RequestInformationUpdateCompanyAgreementsParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () =>
        APIEndpoints.customerAssurance.requestInformation
          .UPDATE_COMPANY_AGREEMENTS,
      body: (data) => {
        if (data.privacyPolicyUrl === "") {
          data.privacyPolicyUrl = undefined;
        }
        if (data.termsOfServiceUrl === "") {
          data.termsOfServiceUrl = undefined;
        }
        return data;
      },
      extraOptions: { checkRunTypes: false },
      invalidatesTags: ["ProfileVersion"],
    }),
    updateStyleSettings: mutationEndpointBuilder<
      void,
      StyleSettingResponse,
      UpdateStyleSettingsParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () =>
        APIEndpoints.customerAssurance.style_settings.UPDATE_STYLE_SETTINGS,
      body: (data) => data,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion"],
    }),
    createTileItem: mutationEndpointBuilder<
      void,
      TileItemResponse,
      CreateTileItemParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => APIEndpoints.customerAssurance.tiles.CREATE_TILE_ITEM,
      body: (data) => data,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion"],
    }),
    deleteTileItem: mutationEndpointBuilder<void, void, string>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.customerAssurance.tiles.DELETE_TILE_ITEM,
      body: (tileItemId) => ({ tileItemId }),
      invalidatesTags: ["ProfileVersion"],
      extraOptions: {
        checkRunTypes: false,
      },
    }),
    updateTileItem: mutationEndpointBuilder<
      void,
      TileItemResponse,
      UpdateTileItemParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => APIEndpoints.customerAssurance.tiles.UPDATE_TILE_ITEM,
      body: (data) => data,
      invalidatesTags: ["ProfileVersion"],
      extraOptions: {
        checkRunTypes: false,
      },
    }),
    updateTileItemRank: mutationEndpointBuilder<
      void,
      TileItemResponse,
      UpdateTileItemRankParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => APIEndpoints.customerAssurance.tiles.UPDATE_TILE_ITEM_RANK,
      body: (data) => data,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion"],
    }),
    createLogo: mutationEndpointBuilder<
      void,
      LogoResponse,
      CreateLogoParams & { logoFile: File }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => APIEndpoints.customerAssurance.logos.CREATE_LOGO,
      body: (data) => {
        const form = new FormData();
        form.append("profileVersionId", data.profileVersionId);
        form.append("homepageUrl", data.homepageUrl);
        form.append("logo", data.logoFile);
        return form;
      },
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion"],
    }),
    updateLogo: mutationEndpointBuilder<
      void,
      LogoResponse,
      UpdateLogoParams & { logoFile?: File }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => APIEndpoints.customerAssurance.logos.UPDATE_LOGO,
      body: (data) => {
        const form = new FormData();
        form.append("logoId", data.logoId);
        form.append("homepageUrl", data.homepageUrl);
        if (data.logoFile) {
          form.append("logo", data.logoFile);
        }
        return form;
      },
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion"],
    }),
    deleteLogo: mutationEndpointBuilder<void, void, string>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.customerAssurance.logos.DELETE_LOGO,
      body: (logoId) => ({ logoId }),
      invalidatesTags: ["ProfileVersion"],
    }),
    upsertCustomerAssuranceNDA: mutationEndpointBuilder<
      void,
      DocusignNdaResponse | IroncladNdaResponse | FileBasedNdaResponse,
      UpsertNDADocumentParams & { file?: RcFile }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => APIEndpoints.customerAssurance.nda.UPSERT_NDA,
      body: (data) => {
        const form = new FormData();
        form.append("profileVersionId", data.profileVersionId);
        if (data.file) {
          form.append("file", data.file);
        }
        if (data.isDownloadable) {
          form.append("isDownloadable", data.isDownloadable.toString());
        }
        if (data.discardExistingProfileNdaAllowlist) {
          form.append(
            "discardExistingProfileNdaAllowlist",
            data.discardExistingProfileNdaAllowlist.toString(),
          );
        }
        if (data.ndaDisclaimer) {
          form.append("ndaDisclaimer", data.ndaDisclaimer);
        }
        return form;
      },
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion", "ProfileNdaAllowlist"],
    }),
    deleteCustomerAssuranceNDA: mutationEndpointBuilder<
      void,
      void,
      CustomerAssuranceNDAId
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.customerAssurance.nda.DELETE_NDA,
      body: (ndaId) => ({ ndaId }),
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion"],
    }),
    updateTitle: mutationEndpointBuilder<
      void,
      ProfileVersionResponse,
      UpdateProfileVersionTitleParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => APIEndpoints.customerAssurance.profileVersions.UPDATE_TITLE,
      body: (body) => body,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion"],
    }),
    updateHtmlHead: mutationEndpointBuilder<
      void,
      ProfileVersionResponse,
      UpdateProfileVersionHtmlHeadParams & {
        favicon?: RcFile;
        emailLogoFile?: RcFile;
      }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () =>
        APIEndpoints.customerAssurance.profileVersions.UPDATE_HTML_HEAD,
      body: (data) => {
        const form = new FormData();
        form.append("profileVersionId", data.profileVersionId);
        if (data.htmlHeadTitle) {
          form.append("htmlHeadTitle", data.htmlHeadTitle);
        }
        if (data.htmlHeadDescription) {
          form.append("htmlHeadDescription", data.htmlHeadDescription);
        }
        if (data.htmlSnippet) {
          form.append("htmlSnippet", data.htmlSnippet);
        }
        if (data.favicon) {
          form.append("favicon", data.favicon);
        }
        if (data.emailLogoFile) {
          form.append("emailLogoFile", data.emailLogoFile);
        }
        return form;
      },
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion"],
    }),
    getPrivateDataRoomLinks: queryEndpointBuilder<
      Meta,
      PrivateDataroomLinkResponse[],
      GetPrivateDataRoomLinksParams
    >({
      builder,
      metaRuntype: undefined,
      dataRuntype: undefined,
      url: (params) => {
        return `${
          APIEndpoints.customerAssurance.profiles.GET_PRIVATE_DATA_ROOM_LINKS
        }?${constructPrivateDataRoomLinksQueryParams(params)}`;
      },
      providesTags: ["DataRoomLinks"],
    }),
    getPrivateDataRoomLinkEvents: queryEndpointBuilder<
      Meta,
      PrivateDataRoomEventResponse[],
      PrivateDataRoomLinkEventsParams & {
        linkId: string;
        filters: DataRoomEventsFilters;
      }
    >({
      builder,
      metaRuntype: undefined,
      dataRuntype: undefined,
      url: ({ linkId, filters }) => {
        let url =
          APIEndpoints.customerAssurance.privateDataRooms.GET_PRIVATE_DATA_ROOM_EVENTS(
            linkId,
          );
        const searchParams = constructPrivateDataRoomEventsQueryParams(filters);
        if (searchParams.length > 0) {
          url = `${url}?${searchParams}`;
        }
        return url;
      },
    }),
    updateRequestInfoTitle: mutationEndpointBuilder<
      void,
      RequestInformationSettingResponse,
      UpdateRequestInfoTitleParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => APIEndpoints.customerAssurance.requestInformation.UPDATE_TITLE,
      body: (body) => body,
      extraOptions: { checkRunTypes: false },
      invalidatesTags: ["ProfileVersion"],
    }),
    updateRequestInfoSubtitle: mutationEndpointBuilder<
      void,
      RequestInformationSettingResponse,
      UpdateRequestInfoSubtitleParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () =>
        APIEndpoints.customerAssurance.requestInformation.UPDATE_SUBTITLE,
      body: (body) => body,
      extraOptions: { checkRunTypes: false },
      invalidatesTags: ["ProfileVersion"],
    }),
    revokePrivateDataRoomLink: mutationEndpointBuilder<void, void, string>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: (id) =>
        APIEndpoints.customerAssurance.privateDataRooms.REVOKE_PRIVATE_DATA_ROOM_LINK(
          id,
        ),
      body: () => ({}),
      invalidatesTags: ["DataRoomLinks"],
    }),
    approvePrivateDataRoomLink: mutationEndpointBuilder<
      void,
      void,
      ApprovePrivateDataRoomLinkParams & { dataRoomLinkId: string }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: (params) =>
        APIEndpoints.customerAssurance.privateDataRooms.APPROVE_PRIVATE_DATA_ROOM_LINK(
          params.dataRoomLinkId,
        ),
      body: (body) => body,
      invalidatesTags: ["DataRoomLinks"],
      extraOptions: { checkRunTypes: false },
    }),
    rejectPrivateDataRoomLink: mutationEndpointBuilder<void, void, string>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: (id) =>
        APIEndpoints.customerAssurance.privateDataRooms.REJECT_PRIVATE_DATA_ROOM_LINK(
          id,
        ),
      body: (body) => body,
      invalidatesTags: ["DataRoomLinks"],
      extraOptions: { checkRunTypes: false },
    }),
    setupIronclad: mutationEndpointBuilder<void, void, SetupIroncladParams>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.customerAssurance.nda.SETUP_IRONCLAD,
      body: (body) => ({ ...body }),
      invalidatesTags: ["ProfileVersion"],
      extraOptions: {
        checkRunTypes: false,
      },
    }),
    disconnectDocusign: mutationEndpointBuilder<
      void,
      void,
      DisconnectDocusignParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.customerAssurance.nda.DISCONNECT_DOCUSIGN,
      body: (body) => body,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion"],
    }),
    connectDocusign: queryEndpointBuilder<void, string, string>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: String,
      url: APIEndpoints.customerAssurance.docusign.GET_DOCUSIGN_CONSENT_URL,
    }),
    getDocusignAccountTemplates: queryEndpointBuilder<
      void,
      DocusignTemplate[],
      string
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Array(DocusignTemplateRT),
      url: (id) => {
        const params = new URLSearchParams();
        params.append("ndaId", id);
        return `${
          APIEndpoints.customerAssurance.docusign.GET_DOCUSIGN_ACCOUNT_TEMPLATES
        }?${params.toString()}`;
      },
    }),
    updateDocusignTemplateId: mutationEndpointBuilder<
      void,
      void,
      UpdateDocusignTemplateIdParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.customerAssurance.nda.UPDATE_DOCSIGN_TEMPLATE_ID,
      body: (body) => body,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion"],
    }),
    updateDocumentOrder: mutationEndpointBuilder<
      void,
      void,
      UpdateAssuranceDocumentOrderParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () => APIEndpoints.customerAssurance.documents.UPDATE_DOCUMENT_ORDER,
      body: (body) => body,
      invalidatesTags: ["ProfileVersion"],
    }),
    updateComponentOrder: mutationEndpointBuilder<
      void,
      void,
      UpdateOrderParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: Undefined,
      url: () =>
        APIEndpoints.customerAssurance.profileVersions.UPDATE_COMPONENT_ORDER,
      body: (body) => body,
      invalidatesTags: ["ProfileVersion"],
    }),
    addPublicKey: mutationEndpointBuilder<
      void,
      PublicKeyIssuerId,
      AddCompanyPublicKeyRequest
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => APIEndpoints.customerAssurance.publicKeys.ADD_PUBLIC_KEY,
      body: (data) => {
        const form = new FormData();
        form.append("companyId", data.companyId);
        form.append("publicKey", data.publicKey);
        form.append("keyType", data.keyType);
        return form;
      },
      invalidatesTags: ["ProfileVersion"],
    }),
    getPrivateDataRoomLinkUnauthed: queryEndpointBuilder<
      void,
      PrivateDataroomLinkResponse,
      string
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: (id) =>
        APIEndpoints.customerAssurance.unauthedAccessDataRoom.GET_PRIVATE_DATA_ROOM(
          id,
        ),
    }),
    refreshPrivateDataRoomLinkUnauthed: mutationEndpointBuilder<
      void,
      void,
      RefreshPrivateDataRoomLinkParams & { privateDataRoomLinkId: string }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: (params) =>
        APIEndpoints.customerAssurance.unauthedAccessDataRoom.REFRESH_PRIVATE_DATA_ROOM_LINK(
          params.privateDataRoomLinkId,
        ),
      body: (body) => body,
      extraOptions: {
        unauthed: true,
        checkRunTypes: false,
      },
    }),
    getIssuerId: queryEndpointBuilder<void, PublicKeyIssuerId, CompanyId>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: (companyId) => {
        return `${APIEndpoints.customerAssurance.publicKeys.GET_ISSUER_ID}?companyId=${companyId}`;
      },
      extraOptions: {
        checkRunTypes: false,
      },
    }),
    validateJWT: mutationEndpointBuilder<void, string, JwtTokenParams>({
      builder,
      metaRuntype: Undefined,
      dataRuntype: String,
      url: (params) =>
        APIEndpoints.customerAssurance.publicKeys.VALIDATE_JWT(
          params.privateDataRoomLinkId,
        ),
      body: (params) => ({ jwt_token: params.jwtToken }),
      extraOptions: {
        unauthed: true,
      },
    }),
    getSalesforceAuthUrl: queryEndpointBuilder<
      void,
      GetSalesforceAuthUrl,
      GetSalesforceAuthUrlParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: (params) => {
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append("companyId", params.companyId.toString());
        return `${APIEndpoints.customerAssurance.profiles.GET_SALESFORCE_AUTH_URL}?${urlSearchParams.toString()}`;
      },
      extraOptions: {
        checkRunTypes: false,
      },
    }),
    checkExistingSalesforceTokens: queryEndpointBuilder<
      void,
      CheckExistingSalesforceTokens,
      GetSalesforceAuthUrlParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: (params) => {
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append("companyId", params.companyId.toString());
        return `${APIEndpoints.customerAssurance.profiles.CHECK_EXISTING_SALESFORCE_TOKENS}?${urlSearchParams.toString()}`;
      },
      extraOptions: {
        checkRunTypes: false,
      },
    }),
    updateCompanySalesforceIntegration: mutationEndpointBuilder<
      void,
      CheckExistingSalesforceTokens,
      UpdateCompanySalesforceIntegrationParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () =>
        APIEndpoints.customerAssurance.profiles
          .UPDATE_COMPANY_SALESFORCE_INTEGRATION,
      body: (params) => params,
      extraOptions: {
        checkRunTypes: false,
      },
    }),
    disconnectSalesforce: mutationEndpointBuilder<
      void,
      CheckExistingSalesforceTokens,
      GetSalesforceAuthUrlParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => APIEndpoints.customerAssurance.profiles.DISCONNECT_SALESFORCE,
      body: (params) => params,
      extraOptions: {
        checkRunTypes: false,
      },
    }),
    getProfileNdaAllowlist: queryEndpointBuilder<
      void,
      string[],
      GetProfileNdaAllowlistParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: (params) => {
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append("profileId", params.profileId.toString());
        return `${APIEndpoints.customerAssurance.profiles.GET_NDA_IN_PLACE_EMAILS}?${urlSearchParams.toString()}`;
      },
      extraOptions: {
        checkRunTypes: false,
      },
      providesTags: ["ProfileNdaAllowlist"],
    }),
    getNdaInPlaceStatusForEmails: builder.query<
      GetCheckProfileNdaAllowlistResponse[],
      CheckNDAInPlaceForEmailParams[]
    >({
      async queryFn(params: CheckNDAInPlaceForEmailParams[], __, ___, fetchBq) {
        const userResponses: JSONAPI<
          void,
          GetCheckProfileNdaAllowlistResponse
        >[] = await Promise.all(
          params.map(async (param) => {
            const urlSearchParams = new URLSearchParams();
            urlSearchParams.append("profileId", param.profileId);
            urlSearchParams.append("email", param.email);
            const response = await fetchBq(
              `${APIEndpoints.customerAssurance.profiles.CHECK_NDA_IN_PLACE_FOR_EMAIL_USER}?${urlSearchParams.toString()}`,
            );
            return response as JSONAPI<
              void,
              GetCheckProfileNdaAllowlistResponse
            >;
          }),
        );
        return { data: userResponses.map((r) => r.data) };
      },
    }),
    createProfileSwitcher: mutationEndpointBuilder<
      void,
      CreateProfileSwitcherResponse,
      CreateProfileSwitcherParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () => APIEndpoints.customerAssurance.profiles.PROFILE_SWITCHERS,
      body: (params) => params,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileSwitcher"],
    }),
    updateProfileSwitcher: mutationEndpointBuilder<
      void,
      void,
      UpdateProfileSwitcherParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () =>
        APIEndpoints.customerAssurance.profiles.UPDATE_PROFILE_SWITCHER,
      body: (params) => params,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileSwitcher"],
    }),
    getProfileSwitchers: queryEndpointBuilder<
      void,
      ProfileSwitcherResponse[],
      {
        companyId: string;
      }
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: (params) => {
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append("companyId", params.companyId);
        return `${APIEndpoints.customerAssurance.profiles.GET_PROFILE_SWITCHERS}?${urlSearchParams.toString()}`;
      },
      extraOptions: {
        checkRunTypes: false,
      },
      providesTags: ["ProfileSwitcher"],
    }),
    updateProfileSwitcherForProfileVersion: mutationEndpointBuilder<
      void,
      void,
      UpdateSwitcherForProfileVersionParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () =>
        APIEndpoints.customerAssurance.profileVersions.UPDATE_PROFILE_SWITCHER,
      body: (params) => params,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileVersion"],
    }),
    deleteProfileSwitcher: mutationEndpointBuilder<
      void,
      void,
      DeleteProfileSwitcherParams
    >({
      builder,
      metaRuntype: Undefined,
      dataRuntype: undefined,
      url: () =>
        APIEndpoints.customerAssurance.profiles.DELETE_PROFILE_SWITCHER,
      body: (params) => params,
      extraOptions: {
        checkRunTypes: false,
      },
      invalidatesTags: ["ProfileSwitcher"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateProfileMutation,
  useGetProfileQuery,
  useGetProfilesQuery,
  useGetProfileUsageStatsQuery,
  useGetProfileVersionQuery,
  useGetProfileVersionsQuery,
  useGetPublicProfileVersionQuery,
  useGetSubscribersQuery,
  useCreateDraftProfileVersionMutation,
  useDeleteDraftProfileVersionMutation,
  useUpdateProfileMutation,
  useUpdateOverviewMutation,
  useUpdateOverviewTitleMutation,
  useDeleteProfileMutation,
  useFinalizeDraftProfileVersionMutation,
  useChangeProfileVersionTypeMutation,
  useUpdateInUseMutation,
  useUpdateCertificationSelectionsMutation,
  useUpdateCertificationSelectionRankMutation,
  useGetCertificationsQuery,
  useCreateCertificationMutation,
  usePostProcessCertificationAfterSignedUploadMutation,
  useUpdateCertificationMutation,
  useDeleteCertificationMutation,
  useGetCustomerAssuranceDocumentGroupsQuery,
  useCreateCustomerAssuranceDocumentGroupMutation,
  useGetCustomerAssuranceDocumentsQuery,
  useGetCustomerAssurancePrivateDocumentsQuery,
  useCreateCustomerAssuranceDocumentMutation,
  useDeleteCustomerAssuranceDocumentMutation,
  useUpdateCustomerAssuranceDocumentMutation,
  useCreateAssuranceProfileUpdateMutation,
  useDeleteAssuranceProfileUpdateMutation,
  useUpdateAssuranceProfileUpdateMutation,
  useCreateSubprocessorMutation,
  useUpdateSubprocessorMutation,
  useUpdateSubprocessorRankMutation,
  useDeleteSubprocessorMutation,
  useCreateTileMutation,
  useUpdateTileMutation,
  useUpdateTileRankMutation,
  useDeleteTileMutation,
  useUpdateRequestInformationSettingsMutation,
  useUpdateRequestInformationCompanyAgreementsMutation,
  useGetInformationRequestsQuery,
  useApproveInformationRequestMutation,
  useRejectInformationRequestMutation,
  useUpdateStyleSettingsMutation,
  useCreateTileItemMutation,
  useDeleteTileItemMutation,
  useUpdateTileItemMutation,
  useUpdateTileItemRankMutation,
  useCreateLogoMutation,
  useUpdateTitleMutation,
  useUpdateHtmlHeadMutation,
  useUpdateLogoMutation,
  useDeleteLogoMutation,
  useUpsertCustomerAssuranceNDAMutation,
  useDeleteCustomerAssuranceNDAMutation,
  useVerifyPrivateDataRoomLinkQuery,
  useGeneratePrivateDataRoomLinkMutation,
  useSendMagicLinkEmailMutation,
  useAuthenticateMagicLinkMutation,
  useUpdateRequestInfoTitleMutation,
  useUpdateRequestInfoSubtitleMutation,
  useGetPrivateDataRoomLinksQuery,
  useGetPrivateDataRoomLinkEventsQuery,
  useRevokePrivateDataRoomLinkMutation,
  useUpdateCustomerAssuranceDocumentGroupMutation,
  useDeleteCustomerAssuranceDocumentGroupMutation,
  useGetDocusignAccountTemplatesQuery,
  useUpdateDocusignTemplateIdMutation,
  useUpdateDocumentOrderMutation,
  useDisconnectDocusignMutation,
  useLazyConnectDocusignQuery,
  useSetupIroncladMutation,
  useUpdateComponentOrderMutation,
  useAddPublicKeyMutation,
  useGetPrivateDataRoomLinkUnauthedQuery,
  useGetIssuerIdQuery,
  useValidateJWTMutation,
  useRefreshPrivateDataRoomLinkUnauthedMutation,
  useGetSalesforceAuthUrlQuery,
  useCheckExistingSalesforceTokensQuery,
  useUpdateCompanySalesforceIntegrationMutation,
  useDisconnectSalesforceMutation,
  useGetProfileNdaAllowlistQuery,
  useApprovePrivateDataRoomLinkMutation,
  useRejectPrivateDataRoomLinkMutation,
  useGetNdaInPlaceStatusForEmailsQuery,
  useCreateProfileSwitcherMutation,
  useGetProfileSwitchersQuery,
  useUpdateProfileSwitcherMutation,
  useUpdateProfileSwitcherForProfileVersionMutation,
  useDeleteProfileSwitcherMutation,
  util: customerAssuranceUtil,
} = extendedApi;
