import { skipToken } from "@reduxjs/toolkit/query/react";
import {
  Button,
  Card,
  Divider,
  Grid,
  Segmented,
  Select,
  Typography,
} from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import AppHeader from "../../components/AppHeader";
import CompanySelector from "../../components/CompanySelector";
import SingleProductSelector from "../../components/SingleProductSelector";
import { useGetProfilesQuery } from "../../features/API/CustomerAssurance";
import { useGetMeQuery } from "../../features/API/auth";
import { CompanyId, Product } from "../../features/API/types";
import { clearActiveProduct, setActiveProduct } from "../../features/Layout";
import { useActiveProduct, useAppSelector } from "../../utils/hooks";
import { useGetCompanyFromProductId } from "../../utils/hooks";
import styles from "./AnalyticsContainer.module.scss";
import AssuranceProfileAnalyticsContainer from "./components/AssuranceProfileAnalyticsContainer";
import ConciergeAnalyticsContainer from "./components/ConciergeContainer";
import VendorAssessAnalyticsContainer from "./components/VendorAssessAnalyticsContainer";
import { ThemeType } from "./utils";

export enum DateFilterEnum {
  LAST_3_MONTHS = "last_3_months",
  LAST_6_MONTHS = "last_6_months",
  LAST_YEAR = "last_year",
}
interface ProfileOption {
  value: string;
  label: string;
}

export interface BaseChartProps {
  height: number;
  width: number;
  activeFilter: DateFilterEnum;
  theme: ThemeType;
}

const { Text } = Typography;

const AnalyticsContainer = (): JSX.Element => {
  const [containerDimensions, setContainerDimensions] = useState({
    height: 450,
    width: 900,
  });
  const dispatch = useDispatch();

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const { clientHeight, clientWidth } = containerRef.current;

        const paddingY = 100; // vertical padding in pixels

        setContainerDimensions({
          height: Math.max(clientHeight - paddingY, 300),
          width: clientWidth,
        });
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const activeProduct = useActiveProduct();

  enum SelectDropDownValue {
    CONCIERGE = "concierge",
    ASSURANCE_PROFILE = "assurance_profile",
    VENDOR_ASSESS = "vendor_assess",
  }

  const { data: meData } = useGetMeQuery();
  const bigQueryAnalyticsSearchEnabled =
    meData?.knownFeatureFlags["big-query-analytics-enabled"];
  const isDarkModeOn = useAppSelector((state) => state.layout.isDarkModeOn);
  const isStaff = meData?.user.attributes.isStaff;
  const theme = isDarkModeOn ? "dark" : "light";
  const [selectedValue, setSelectedValue] = useState<SelectDropDownValue>(
    SelectDropDownValue.CONCIERGE,
  );
  const [activeFilter, setActiveFilter] = useState<DateFilterEnum>(
    DateFilterEnum.LAST_YEAR,
  );
  const [selectedProfileIds, setSelectedProfileIds] = useState<string[]>([]);

  const companyId = useGetCompanyFromProductId(activeProduct?.id);

  const [selectedCompanyId, setSelectedCompanyId] = useState<
    CompanyId | undefined
  >(companyId);

  const { data: profilesData, isLoading: profilesLoading } =
    useGetProfilesQuery(
      selectedValue === SelectDropDownValue.ASSURANCE_PROFILE &&
        selectedCompanyId
        ? { companyId: selectedCompanyId }
        : skipToken,
    );

  // Get profile options for select
  const profileOptions = useMemo(
    () =>
      profilesData?.data.map((profile) => ({
        label: profile.attributes.name,
        value: profile.id!,
      })) || [],
    [profilesData],
  );

  useEffect(() => {
    if (profileOptions.length > 0) {
      setSelectedProfileIds([profileOptions[0].value]);
    } else {
      setSelectedProfileIds([]);
    }
  }, [profileOptions, selectedCompanyId, selectedValue]);

  const handleFilterClick = (filter: DateFilterEnum) => {
    setActiveFilter(filter);
  };

  const handleMenuClick = (value: SelectDropDownValue) => {
    setSelectedValue(value);
    // Reset profile selection when switching
    setSelectedProfileIds([]);
  };

  const statsFilterOptions = [
    {
      value: DateFilterEnum.LAST_3_MONTHS,
      label: "Last 3 months",
    },
    {
      value: DateFilterEnum.LAST_6_MONTHS,
      label: "Last 6 months",
    },
    {
      value: DateFilterEnum.LAST_YEAR,
      label: "Last year",
    },
  ];

  const dispatchNewProduct = (product?: Product) => {
    if (product) {
      dispatch(setActiveProduct(product));
    }
  };

  return (
    <>
      <AppHeader
        title="Analytics"
        extra={
          <div className={styles.CompanyProductSelectorContainer}>
            {isStaff && (
              <CompanySelector
                className={styles.CompanySelector}
                value={selectedCompanyId}
                onChange={(companyId) => {
                  dispatch(clearActiveProduct());
                  setSelectedCompanyId(companyId);
                }}
              />
            )}
            {selectedValue !== SelectDropDownValue.VENDOR_ASSESS &&
              selectedValue !== SelectDropDownValue.ASSURANCE_PROFILE && (
                <div>
                  {!isStaff && <Text>Library: </Text>}
                  <SingleProductSelector
                    companyId={selectedCompanyId}
                    onChange={dispatchNewProduct}
                    className={styles.ProductSelector}
                  />
                </div>
              )}
          </div>
        }
      />
      <Card className={styles.AnalyticsContainer}>
        <div className={styles.AnalyticsHeader}>
          <div className={styles.AnalyticsDropdownContainer}>
            <Select
              className={styles.AnalyticsDropdown}
              defaultValue={SelectDropDownValue.CONCIERGE}
              onSelect={handleMenuClick}
              popupMatchSelectWidth={false}
              options={[
                { value: SelectDropDownValue.CONCIERGE, label: "Concierge" },
                {
                  value: SelectDropDownValue.ASSURANCE_PROFILE,
                  label: "Assurance Profile",
                },
                {
                  value: SelectDropDownValue.VENDOR_ASSESS,
                  label: "Vendor Assess",
                },
              ]}
            />
            {selectedValue === SelectDropDownValue.ASSURANCE_PROFILE && (
              <Select<string[], ProfileOption>
                className={styles.ProfileSelect}
                placeholder="Select a Profile"
                allowClear
                mode="multiple"
                options={profileOptions}
                onChange={(values) => setSelectedProfileIds(values)}
                showSearch
                filterOption={(inputVal, option) =>
                  option?.label
                    ?.toLowerCase()
                    .includes(inputVal.toLowerCase()) ?? false
                }
                value={selectedProfileIds}
                loading={profilesLoading}
                dropdownRender={(menu) => (
                  <>
                    {profileOptions.length > 1 && (
                      <div className={styles.SelectAllContainer}>
                        <Button
                          className={styles.SelectAllButton}
                          type="text"
                          onClick={() => {
                            // If not all profiles are selected, select all
                            if (
                              selectedProfileIds.length !==
                              profileOptions.length
                            ) {
                              setSelectedProfileIds(
                                profileOptions.map((option) => option.value),
                              );
                            } else {
                              // If all are selected, deselect all
                              setSelectedProfileIds([]);
                            }
                          }}
                        >
                          <Typography.Text strong>
                            {selectedProfileIds.length !== profileOptions.length
                              ? "Select all"
                              : "Deselect all"}
                          </Typography.Text>
                        </Button>
                        <Divider className={styles.SelectAllDivider} />
                      </div>
                    )}
                    {menu}
                  </>
                )}
              />
            )}
          </div>
          {screens.lg ? (
            <div className={styles.AnalyticsFilter}>
              <Segmented
                options={statsFilterOptions.map((filter) => ({
                  label: filter.label,
                  value: filter.value,
                }))}
                value={activeFilter}
                onChange={(value) => handleFilterClick(value as DateFilterEnum)}
              />
            </div>
          ) : (
            <div className={styles.AnalyticsFilterDropdown}>
              <Select
                value={activeFilter}
                onChange={(value) => handleFilterClick(value as DateFilterEnum)}
                options={statsFilterOptions}
                style={{ width: "100%" }}
              />
            </div>
          )}
        </div>

        {
          <div className={styles.AnalyticsBody} ref={containerRef}>
            {selectedValue === SelectDropDownValue.CONCIERGE &&
              activeProduct && (
                <ConciergeAnalyticsContainer
                  productId={activeProduct.id}
                  activeFilter={activeFilter}
                  theme={theme}
                  bigQueryAnalyticsSearchEnabled={
                    bigQueryAnalyticsSearchEnabled
                  }
                  height={containerDimensions.height}
                  width={containerDimensions.width}
                />
              )}
            {selectedValue === SelectDropDownValue.ASSURANCE_PROFILE && (
              <AssuranceProfileAnalyticsContainer
                activeFilter={activeFilter}
                selectedProfileIds={selectedProfileIds}
                theme={theme}
                height={containerDimensions.height}
                width={containerDimensions.width}
              />
            )}
            {selectedValue === SelectDropDownValue.VENDOR_ASSESS && (
              <VendorAssessAnalyticsContainer
                companyId={companyId}
                activeFilter={activeFilter}
                theme={theme}
                height={containerDimensions.height}
                width={containerDimensions.width}
              />
            )}
          </div>
        }
      </Card>
    </>
  );
};

export default AnalyticsContainer;
