import clsx from "clsx";
import DOMPurify from "dompurify";
import linkifyHtml from "linkify-html";
import { useEffect, useRef } from "react";
import React from "react";
import styles from "./SanitizedHTML.module.scss";

interface SanitizedHTMLProps extends React.ComponentPropsWithoutRef<"span"> {
  html: string;
  subtextRef?: React.RefObject<HTMLSpanElement>;
  breakWord?: boolean;
}

//DOM purify removes target but it should be safe with rel=noopener. See https://github.com/cure53/DOMPurify/issues/317
DOMPurify.addHook("afterSanitizeAttributes", function (node) {
  // set all elements owning target to target=_blank
  if ("target" in node) {
    node.setAttribute("target", "_blank");
    node.setAttribute("rel", "noopener");
  }
});

const SanitizedHTML = ({
  html,
  subtextRef,
  breakWord,
  className,
}: SanitizedHTMLProps): JSX.Element => {
  const spanRef = useRef<HTMLSpanElement>(null);
  const contentRef = subtextRef || spanRef;

  useEffect(() => {
    const element = contentRef.current;
    if (!element) return;
    const anchorTags = element.querySelectorAll("a");
    anchorTags.forEach((anchorTag) => {
      const href = anchorTag.getAttribute("href");
      if (!href) return;
      const title = anchorTag.getAttribute("title");
      if (title) return;
      anchorTag.setAttribute("title", href);
    });
  }, [html, contentRef]);

  const sanitizedHtml = DOMPurify.sanitize(html);
  const linkifiedHtml = linkifyHtml(sanitizedHtml);

  return (
    <span
      ref={contentRef}
      dangerouslySetInnerHTML={{ __html: linkifiedHtml }}
      className={clsx(
        styles.SanitizedHTMLText,
        { [styles.WordBreakSpan]: breakWord },
        className,
      )}
    />
  );
};

export default SanitizedHTML;
