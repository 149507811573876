import { InfoCircleOutlined } from "@ant-design/icons";
import { Input, Segmented, Tooltip, Typography } from "antd";
import { useState } from "react";
import { BaseChartProps, DateFilterEnum } from "..";
import { CompanyId } from "../../../features/API/types";
import { ThemeType } from "../utils";
import styles from "./ContainerBase.module.scss";
import tabStyles from "./TabsStyles.module.scss";
import VendorAssessAssessmentCompletedColumnChart from "./VendorAssessAssessmentCompletedColumnChart";
import VendorAssessTimeSavedColumnChart from "./VendorAssessTimeSavedColumnChart";

enum TabKey {
  TimeSaved = "timeSaved",
  AssessmentsCompleted = "assessmentsCompleted",
}

interface VendorAssessAnalyticsContainerProps {
  companyId: CompanyId | undefined;
  activeFilter: DateFilterEnum;
  theme: ThemeType;
  height: number;
  width: number;
}

export interface VendorAssessChartProps extends BaseChartProps {
  companyId: CompanyId | undefined;
}

const VendorAssessAnalyticsContainer = ({
  companyId,
  activeFilter,
  theme,
  height,
  width,
}: VendorAssessAnalyticsContainerProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<TabKey>(TabKey.TimeSaved);
  const [timeSavedInputValue, setTimeSavedInputValue] = useState<number>(4);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "-") {
      e.preventDefault();
    }
    const value = parseFloat((e.target as HTMLInputElement).value);
    if (!isNaN(value) && value > 0) {
      setTimeSavedInputValue(value);
    }
  };

  const options = [
    { label: "Time Saved", value: TabKey.TimeSaved },
    { label: "Assessments Completed", value: TabKey.AssessmentsCompleted },
  ];

  const renderContent = () => {
    switch (selectedTab) {
      case TabKey.TimeSaved:
        return (
          <div className={styles.TimeSavedChartContainer}>
            <div className={styles.TimeSavedHeader}>
              <div className={styles.TimeSavedHeaderInputContainer}>
                <div className={styles.TimeSavedHeaderInputTitleContainer}>
                  <Typography.Text className={styles.TimeSavedHeaderInputTitle}>
                    Estimated hours
                  </Typography.Text>
                  <Tooltip
                    title="Estimated time (in hours) it would take for you to complete a vendor assessment."
                    placement="topRight"
                  >
                    <InfoCircleOutlined
                      className={styles.TimeSavedHeaderIcon}
                    />
                  </Tooltip>
                </div>
                <div className={styles.TimeSavedHeaderInput}>
                  <Input
                    defaultValue={timeSavedInputValue}
                    type="number"
                    min={1}
                    max={1000}
                    placeholder="Hrs"
                    onKeyUp={handleKeyPress}
                  />
                </div>
              </div>
            </div>
            <div className={styles.TimeSavedColumnChart}>
              <VendorAssessTimeSavedColumnChart
                companyId={companyId}
                activeFilter={activeFilter}
                theme={theme}
                height={height}
                width={width}
                timeSavedInHrs={timeSavedInputValue}
              />
            </div>
          </div>
        );
      case TabKey.AssessmentsCompleted:
        return (
          <div className={styles.AnalyticsChart}>
            <VendorAssessAssessmentCompletedColumnChart
              companyId={companyId}
              activeFilter={activeFilter}
              theme={theme}
              height={height}
              width={width}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={tabStyles.TabsContainer}>
      <div className={tabStyles.SegmentedTabs}>
        <Segmented
          value={selectedTab}
          onChange={(value) => setSelectedTab(value as TabKey)}
          options={options}
        />
      </div>
      {renderContent()}
    </div>
  );
};

export default VendorAssessAnalyticsContainer;
