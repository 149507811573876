import { Line } from "@ant-design/plots";
import { Skeleton } from "antd";
import { useMemo } from "react";
import { useGetConciergeCompletionPercentageAnalyticsQuery } from "../../../features/API/AnalyticsDashboard";
import { CompletionPercentageStats } from "../../../features/API/AnalyticsDashboard/types";
import { filterData, getAnalyticsMessageComponent } from "../utils";
import { ConceirgeChartProps } from "./ConciergeContainer";

/**
 * Component that displays metrics for the KL updates as a column chart
 * @param {CompletionPercentageChartProps} props - Component props
 */
const CompletionPercentageLineChart = ({
  productId,
  activeFilter,
  theme,
  height,
  width,
}: ConceirgeChartProps): JSX.Element => {
  const {
    data: completionPercentageStatsData,
    isLoading: statsLoading,
    error: statsError,
  } = useGetConciergeCompletionPercentageAnalyticsQuery({
    productId,
  });

  const filteredData = useMemo(() => {
    if (!completionPercentageStatsData?.data?.completionPercentageStats)
      return [];

    return filterData(
      completionPercentageStatsData.data.completionPercentageStats,
      activeFilter,
    );
  }, [completionPercentageStatsData, activeFilter]);

  if (statsLoading) {
    return <Skeleton active />;
  }

  if (statsError) {
    return getAnalyticsMessageComponent({
      type: "error",
      title: "Failed to Load Completion Percenatage Statistics",
      subTitle:
        "An unexpected error occurred while fetching Completion Percentage data",
    });
  }

  if (
    !completionPercentageStatsData ||
    !completionPercentageStatsData?.data?.completionPercentageStats ||
    completionPercentageStatsData?.data?.completionPercentageStats.every(
      (stat) => stat.percentage === 0,
    )
  ) {
    return getAnalyticsMessageComponent({
      type: "info",
      title: "No Completion Percentage Data",
      subTitle: "There are no Completion Percentage statistics available",
    });
  }

  const chartConfig = {
    theme,
    data: filteredData,
    xField: "date",
    yField: "percentage",
    padding: "auto",
    height,
    width,
    point: {
      shapeField: "square",
      sizeField: 3,
    },
    interaction: {
      tooltip: {
        marker: false,
      },
    },
    style: {
      lineWidth: 2,
    },
    axis: {
      y: { title: "Percentage" },
      x: { title: "Month" },
    },
    label: {
      text: (d: CompletionPercentageStats) => `${(d.percentage).toFixed(1)}%`,
      textBaseline: "bottom",
    },
  };

  return <Line {...chartConfig} />;
};

export default CompletionPercentageLineChart;
