// Import necessary libraries and components
import { InfoCircleOutlined } from "@ant-design/icons";
import { Pie } from "@ant-design/plots";
import { Skeleton, Tooltip, Typography } from "antd";
import { format, sub } from "date-fns";
import { useMemo } from "react";
import { DateFilterEnum } from "..";
import { useGetConciergeAnnualRevenueAnalyticsQuery } from "../../../features/API/AnalyticsDashboard";
import { ConciergeAnnualRevenueStats } from "../../../features/API/AnalyticsDashboard/types";
import { useActiveProduct } from "../../../utils/hooks";
import { getAnalyticsMessageComponent } from "../utils";
import styles from "./AnnualRevenuePieChart.module.scss";
import { ConceirgeChartProps } from "./ConciergeContainer";

const AnnualRevenuePieChart = ({
  productId,
  activeFilter,
  theme,
  height,
  width,
}: ConceirgeChartProps): JSX.Element => {
  const activeProduct = useActiveProduct();
  const companyName =
    activeProduct?.relationships.company.data.attributes.displayName;
  const {
    data: annualRevenueStats,
    isLoading: isAnnualRevenueStatsLoading,
    error: isAnnnualRevenueStatsError,
  } = useGetConciergeAnnualRevenueAnalyticsQuery({
    productId: productId,
    dateFilter: activeFilter,
  });

  const monthsMap = {
    [DateFilterEnum.LAST_3_MONTHS]: 3,
    [DateFilterEnum.LAST_6_MONTHS]: 6,
    [DateFilterEnum.LAST_YEAR]: 12,
  };

  const currentDate = new Date();
  const lastMonth = sub(currentDate, { months: 1 });
  const startDate = sub(currentDate, {
    months: monthsMap[activeFilter],
  });

  const timeRange = useMemo(
    () => `${format(startDate, "MMM yyyy")} - ${format(lastMonth, "MMM yyyy")}`,
    [startDate, lastMonth],
  );

  if (isAnnualRevenueStatsLoading) {
    return <Skeleton active />;
  }
  if (isAnnnualRevenueStatsError) {
    return getAnalyticsMessageComponent({
      type: "error",
      title: "Failed to Load Annual Revenue Statistics",
      subTitle:
        "An unexpected error occurred while fetching annual revenue data",
    });
  }

  if (
    !annualRevenueStats ||
    !annualRevenueStats.data.length ||
    annualRevenueStats.data.every((stat) => stat.percentage === 0)
  ) {
    return getAnalyticsMessageComponent({
      type: "info",
      title: "No Annual Revenue Data",
      subTitle: "There are no annual revenue statistics available",
    });
  }
  const config = {
    theme,
    data: annualRevenueStats.data,
    angleField: "percentage",
    colorField: "range",
    radius: 0.8,
    height,
    width,
    label: {
      position: "outside",
      text: (data: ConciergeAnnualRevenueStats) =>
        `${data.questionnaireCount} ${data.questionnaireCount === 1 ? "Questionnaire" : "Questionnaires"} (${data.percentage}%)`,
      style: {
        fontWeight: "bold",
      },
    },
    tooltip: {
      items: [{ name: "Revenue Range", channel: "color" }],
    },
    legend: {
      title: "Annual Recurring Revenue Range",
      color: {
        position: "right",
        rowPadding: 10,
        itemMarkerSize: 15,
        title: "Revenue Ranges",
        titlePosition: "top",
        showTitle: true,
        titleFontSize: 14,
        titleSpacing: 15,
        titleFontWeight: 1000,
      },
    },
  };

  return (
    <div className={styles.AnnualRevenueChartContainer}>
      <div className={styles.AnnualRevenueChartTitleContainer}>
        <Typography.Title level={5} className={styles.AnnualRevenueChartTitle}>
          Distribution of annual recurring revenue from {timeRange}
        </Typography.Title>
        <Tooltip
          title={`This chart displays the annual recurring revenue generated based on the security questionnaires answered. ARR values are inputted optionally by ${companyName} users when submitting a questionnaire.`}
          placement="bottomRight"
        >
          <InfoCircleOutlined className={styles.InfoIcon} />
        </Tooltip>
      </div>
      <Pie {...config} />
    </div>
  );
};

export default AnnualRevenuePieChart;
