import { Cascader, Select, Typography } from "antd";
import clsx from "clsx";
import { SingleValueType } from "rc-cascader/lib/Cascader";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { CompanyId, Product, ProductId } from "../../features/API/types";
import {
  MULTIPLE_LIBRARY_IDS_QUERY_KEY,
  getProductParams,
} from "../../features/KnowledgeLibrary/utils";
import { selectSortedProducts, setActiveProduct } from "../../features/Layout";
import { groupBy } from "../../utils/helper";
import { useActiveProduct, useAppSelector } from "../../utils/hooks";
import styles from "./ProductSelector.module.css";

interface CompanyOption {
  value: CompanyId | string;
  label: string;
  children: ProductOption[];
}

interface ProductOption {
  value: ProductId | number;
  label: string;
}
interface ProductSelectorProps {
  size?: "small" | "middle" | "large";
  className?: string;
  onChange?: (product: Product | undefined) => void;
  value?: Product;
  // This property controls if the dropdown should expand to either right side of the selector or left side. In most parts of the app, the selector is usually present on the right side of the screen. So, when the dropdown is expanded, there might not be enough space for dropdown to expand to the right. Hence, we expand the dropdown to the left. In cases where the dropdown is present on the left side of the screen, we cannot expand dropdown to the left since there might not be enough space for the dropdown to expand to the left. So, in those cases, the dropdown should expand to the right.
  expandDropDownToRight?: boolean;
}

export const ProductSelector = ({
  size,
  className,
  onChange,
  value,
  expandDropDownToRight = false,
}: ProductSelectorProps): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const productIds = getProductParams(searchParams);
  const parsedProductIdParam = productIds?.[0];

  const products: Product[] = useAppSelector(selectSortedProducts);

  useEffect(() => {
    if (parsedProductIdParam) {
      const product = products.find((p) => p.id === parsedProductIdParam);
      if (product) {
        onChange?.(product);
      }
    }
  }, [parsedProductIdParam, onChange, products]);

  const productsToCascaderOptions = (products: Product[]): CompanyOption[] => {
    const groupedByCompany = groupBy(
      products,
      (p) => p.relationships.company.data.id,
    );
    return (
      Object.values(groupedByCompany)
        .map((products) => {
          const company = products[0].relationships.company.data;
          const children: ProductOption[] = products
            .map((el) => ({
              value: el.id,
              label: el.attributes.productName,
            }))
            //sort alphabetically
            .sort((a, b) =>
              a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1,
            );
          return {
            value: company.id,
            label: company.attributes.displayName,
            children,
          };
        })
        //sort alphabetically
        .sort((a, b) =>
          a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1,
        )
    );
  };

  const selectedValueCascaderArray = (
    product: Product | null,
  ): (string | number)[] =>
    product ? [product.relationships.company.data.id, product.id] : [];

  const cascaderOptions = productsToCascaderOptions(products);

  return cascaderOptions.length > 1 ? (
    <Cascader<CompanyOption | ProductOption>
      onChange={(value: SingleValueType) => {
        if (onChange) {
          const castValues = value as number[];
          const productId = castValues.pop() as ProductId;
          const product = products.find((p) => p.id === productId);
          onChange(product);
          if (parsedProductIdParam) {
            setSearchParams((prev) => {
              if (product) {
                prev.set(MULTIPLE_LIBRARY_IDS_QUERY_KEY, product.id.toString());
              } else {
                prev.delete(MULTIPLE_LIBRARY_IDS_QUERY_KEY);
              }
              return prev;
            });
          }
        }
      }}
      allowClear={false}
      placeholder="Select library"
      showSearch
      aria-label="Library selector"
      size={size}
      value={value ? selectedValueCascaderArray(value) : undefined}
      className={clsx(className, styles.ProductSelector)}
      options={cascaderOptions}
      expandTrigger="hover"
      popupClassName={styles.PopupContainer}
      getPopupContainer={(trigger) => trigger.parentElement ?? document.body}
      dropdownStyle={
        expandDropDownToRight
          ? { left: "0", right: "auto" }
          : { left: "auto", right: "0" }
      }
    />
  ) : (
    <Select<ProductId, ProductOption>
      placeholder="Select library"
      showSearch
      onSelect={(value: ProductId) => {
        if (onChange) {
          const product = products.find((p) => p.id === value);
          onChange(product);
          if (parsedProductIdParam) {
            setSearchParams((prev) => {
              if (product) {
                prev.set(MULTIPLE_LIBRARY_IDS_QUERY_KEY, product.id.toString());
              } else {
                prev.delete(MULTIPLE_LIBRARY_IDS_QUERY_KEY);
              }
              return prev;
            });
          }
        }
      }}
      className={clsx(className, styles.ProductSelector)}
      size={size}
      value={value?.id}
      aria-label="Library selector"
    >
      {products.map(({ id, attributes }) => (
        <Select.Option key={id} value={id}>
          {attributes.productName}
        </Select.Option>
      ))}
    </Select>
  );
};

type ActiveProductSelectorProps = Omit<ProductSelectorProps, "onChange">;

const ActiveProductSelector = ({
  size,
  className,
  expandDropDownToRight,
}: ActiveProductSelectorProps): JSX.Element => {
  const dispatch = useDispatch();
  const activeProduct = useActiveProduct();

  const dispatchNewProduct = (product?: Product) => {
    if (product) {
      dispatch(setActiveProduct(product));
    }
  };

  return (
    <ProductSelector
      className={className}
      size={size}
      onChange={dispatchNewProduct}
      value={activeProduct || undefined}
      expandDropDownToRight={expandDropDownToRight}
    />
  );
};

export const InlineProductSelector = (): JSX.Element => {
  const products = useAppSelector(selectSortedProducts);
  if (products.length <= 1) {
    return <></>;
  }

  return (
    <div className={styles.InlineProductSelector}>
      <div className={styles.InlineProductSelector_label}>
        <Typography.Text>Library:</Typography.Text>
      </div>
      <ActiveProductSelector size="middle" />
    </div>
  );
};

export default ActiveProductSelector;
