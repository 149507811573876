/// <reference types="vite-plugin-svgr/client" />
import Wordmark from "../assets/images/security-pal-logo-word-mark.svg?react";
import styles from "./Footer.module.css";

const ProfileFooter = (): JSX.Element => {
  return (
    <div className={styles.Background}>
      Managed by
      <a
        href="https://www.securitypalhq.com/solutions/trust-center?utm_campaign=8158757-Trust%20Center&utm_source=assurance-profile&utm_medium=web&utm_content=assurance-profile-footer"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="SecurityPal's Trust Center"
      >
        <Wordmark className={styles.Wordmark} />
      </a>
    </div>
  );
};

export default ProfileFooter;
