import { Result, Segmented } from "antd";
import { useState } from "react";
import { BaseChartProps, DateFilterEnum } from "..";
import { ProductId } from "../../../features/API/types";
import styles from "./ContainerBase.module.scss";
import tabStyles from "./TabsStyles.module.scss";

import clsx from "clsx";
import { ThemeType } from "../utils";
import DocumentDownloadChart from "./AssuranceProfileDocumentsDownloadChart";
import InformationRequestChart from "./AssuranceProfileInformationRequestChart";
import PageVisitsChart from "./AssuranceProfilePageVisitsChart";
import PrivateDataRoomChart from "./AssuranceProfilePrivateDataRoomChart";

enum TabKey {
  PageViews = "pageViews",
  InformationRequests = "informationRequests",
  PrivateDataRoomsCreated = "privateDataRoomsCreated",
  TotalSubscribers = "totalSubscribers",
}

interface AssuranceProfileAnalyticsContainerProps {
  activeFilter: DateFilterEnum;
  selectedProfileIds: string[];
  theme: ThemeType;
  height: number;
  width: number;
}

export interface AssuranceProfileChartProps extends BaseChartProps {
  selectedProfileIds: string[];
}

const AssuranceProfileAnalyticsContainer = ({
  activeFilter,
  selectedProfileIds,
  theme,
  height,
  width,
}: AssuranceProfileAnalyticsContainerProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<TabKey>(TabKey.PageViews);

  const options = [
    { label: "Page Visits", value: TabKey.PageViews },
    { label: "Information Requests", value: TabKey.InformationRequests },
    {
      label: "Private Data Rooms Created",
      value: TabKey.PrivateDataRoomsCreated,
    },
    { label: "Documents Downloaded", value: TabKey.TotalSubscribers },
  ];

  const renderContent = () => {
    switch (selectedTab) {
      case TabKey.PageViews:
        return (
          <div className={styles.AnalyticsChart}>
            <PageVisitsChart
              selectedProfileIds={selectedProfileIds}
              activeFilter={activeFilter}
              theme={theme}
              height={height}
              width={width}
            />
          </div>
        );
      case TabKey.InformationRequests:
        return (
          <div className={styles.AnalyticsChart}>
            <InformationRequestChart
              selectedProfileIds={selectedProfileIds}
              activeFilter={activeFilter}
              theme={theme}
              height={height}
              width={width}
            />
          </div>
        );
      case TabKey.PrivateDataRoomsCreated:
        return (
          <div className={styles.PrivateDataRoomsCreatedChart}>
            <PrivateDataRoomChart
              selectedProfileIds={selectedProfileIds}
              activeFilter={activeFilter}
              theme={theme}
              height={height}
              width={width}
            />
          </div>
        );
      case TabKey.TotalSubscribers:
        return (
          <div className={styles.DocumentsDownloadedChart}>
            <DocumentDownloadChart
              selectedProfileIds={selectedProfileIds}
              activeFilter={activeFilter}
              theme={theme}
              height={height}
              width={width}
            />
          </div>
        );
      default:
        return <Result status="error" title="Invalid tab selected" />;
    }
  };

  return (
    <div className={clsx(styles.AnalyticsContainer, tabStyles.TabsContainer)}>
      {selectedProfileIds.length > 0 ? (
        <div className={tabStyles.SegmentedTabs}>
          <Segmented
            value={selectedTab}
            onChange={(value) => setSelectedTab(value as TabKey)}
            options={options}
          />
        </div>
      ) : (
        <Result
          status="info"
          title="No profiles selected"
          subTitle="Please select a profile to view analytics."
        />
      )}
      {selectedProfileIds.length > 0 && renderContent()}
    </div>
  );
};

export default AssuranceProfileAnalyticsContainer;
