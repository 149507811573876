import { FileAddOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Typography, message } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeaderInfoPopover } from "shared_frontend/src/InfoPopover";
import AppHeader from "../../../components/AppHeader";
import { PendingQuestionResponse } from "../../../features/pendingQuestions/types";
import EditQuestionModal from "./EditQuestionModal";
import InternalTableView from "./InternalTableView";
import styles from "./PendingQuestionsInternal.module.scss";

const PendingQuestionsInternal = (): JSX.Element => {
  const [questionToEdit, setQuestionToEdit] =
    useState<PendingQuestionResponse>();
  const navigate = useNavigate();
  return (
    <>
      <AppHeader
        title={
          <HeaderInfoPopover
            title="Pending Question Internal"
            content={
              <Typography.Paragraph>
                A place to add, view and edit pending questions
              </Typography.Paragraph>
            }
          />
        }
        extra={
          <div className={styles.ActionButtons}>
            <Button
              icon={<FileAddOutlined />}
              onClick={() =>
                navigate("/pending-questions-internal/bulk-upload-questions/")
              }
            >
              Bulk upload
            </Button>
            <Button
              icon={<UploadOutlined />}
              onClick={() =>
                navigate("/pending-questions-internal/upload-single-question")
              }
              type="primary"
            >
              Upload question
            </Button>
          </div>
        }
      />
      <InternalTableView
        onSelectQuestionToEdit={(pendingQuestion: PendingQuestionResponse) => {
          setQuestionToEdit(pendingQuestion);
        }}
      />
      {questionToEdit && (
        <EditQuestionModal
          onCancel={() => {
            setQuestionToEdit(undefined);
          }}
          onOk={() => {
            setQuestionToEdit(undefined);
            message.success("Question edited successfully", 3);
          }}
          pendingQuestion={questionToEdit}
        />
      )}
    </>
  );
};
export default PendingQuestionsInternal;
